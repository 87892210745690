import {environment} from "../../environments/environment";

export class Constants {
  //General Error Message
  public static FORBIDDEN = 'Forbidden request';
  public static INTERNAL_SERVER_ERROR = 'Internal server error';
  public static BED_REQUEST = 'Bad request';
  public static REQUEST_NOT_FOUND = 'Request not found';
  public static METHOD_NOT_ALLOW = 'Method not allowed';
  public static NOT_ACCEPTABLE = 'Not acceptable';
  public static REQUEST_ENTITY_TOO_LARGE = 'Request entity too large';
  public static UN_SUPPORT_MEDIA = 'Unsupported media type';
  public static REQUEST_TIME_OUT = 'Request timeout';
  public static NOT_IMPLEMENTED = 'Not implemented';
  public static BED_GATEWAY = 'Bad gateway';
  public static SERVICE_UNAVAILABLE = 'Service unavailable';
  public static GATEWAY_TIME_OUT = 'Gateway timeout';

  public static VERIFICATION_REQUIRED = "Y";
  public static PASSWORD_UPDATE_MESSAGE = 'Your password has been updated successfully.';
  public static PRODUCT_IS_FEATURE_PROFILE_YES = 'YES';

  public static QUOTE_IS_EXPIRED_NO = "NO";
  public static QUOTE_IS_EXPIRED_YES = "YES";

  public static USER_STATUS_ACTIVE = 'A';
  public static USER_STATUS_INACTIVE = 'I';
  public static PAGE_SIZE_ALL = 'all';

  public static ACCOUNT_STATUS_ACTIVE = 'A';
  public static ACCOUNT_STATUS_SUSPENDED = 'I';

  public static CONTACT_STATUS_ACTIVE = 'A';
  public static CONTACT_STATUS_SUSPENDED = 'I';
  public static CONTACT_STATUS_DECLINED = 'D';
  public static CONTACT_STATUS_PENDING_APPROVAL = 'N';

  //STATUS
  public static PRODUCT_STATUS_ACTIVE = 'A';
  public static PRODUCT_MATERIAL = 'M';
  public static PRODUCT_FORMAT = 'F';
  public static PRODUCT_CATEGORY = 'C';
  public static QUOTE = 'quote';
  public static STATUS_ACTIVE_SHORT = 'A';
  public static IS_FEATURE_PROFILE_NO = 'NO';
  public static IS_FEATURE_PROFILE_YES = 'YES';

  public static ACTIVE = 'Active';
  public static INACTIVE = 'Inactive';
  public static SUSPEND = 'Suspended';
  public static DECLINED = 'Declined';
  public static FOLLOW_UP = 'Follow Up';
  public static PENDING_APPROVAL = 'Pending Approval'
  public static STATUS_PENDING_APPROVAL = 'I';

  public static TYPE_GENERAL = '1';
  public static TYPE_TRADE = '2';
  public static SURFACE_SORT_NAME = 'S';
  public static SURFACE_SAMPLE_SORT_NAME = 'SS';
  public static DECOR_SORT_NAME = 'D';
  public static PRODUCT_TYPE_SURFACE_ACCESSORY = 'SA';
  public static PRODUCT_TYPE_DECOR_ACCESSORY = 'DA';
  public static SAMPLE_PRODUCT_SORT_NAME = 'SM';

  public static ROLE_TRADE = 'Trade';
  public static ROLE_GENERAL = 'General';
  public static TYPE_WEBSITE_USER = 'Website';
  public static TYPE_SALESFORCE_USER = 'Salesforce';
  public static STATUS_CREATE_QUOTE = 1;
  public static STATUS_CREATE_SAMPLE = 2;
  public static STATUS_QUOTE_SHIPPING_REQUIRED = 4;

  public static ORDER_TYPE_PRODUCT = 'p';
  public static ORDER_TYPE_GUEST_USER = 'gu';

  public static STATUS_QUOTE_ACTIVE = 1;
  public static STATUS_QUOTE_ACCEPT = 2;
  public static STATUS_QUOTE_EXPIRED = 3;
  public static STATUS_REQUEST_DELIVERY_COST = 4;
  public static STATUS_PENDING_DELIVERY_COST = 5;
  public static STATUS_SEND_DELIVERY_REQUEST = 6;
  public static STATUS_PACKAGING = 8;
  public static STATUS_IN_TRANSIT = 10;
  public static ORDER_DEPOSIT_EXPIRY_DAYS = 7;

  public static QUOTES_TYPE_ID = 1;
  public static ORDERS_TYPE_ID = 2;

  public static CONTACT_TYPE_ID = 1;
  public static ACCOUNT_TYPE_ID = 2;

  // Message
  public static SUCCESS_MESSAGE_BUSINESS_ADDRESS_ADDED = 'Business address has been added successfully.';
  public static SUCCESS_MESSAGE_BUSINESS_ADDRESS_UPDATED = 'Business address has been updated successfully.';
  public static SUCCESS_MESSAGE_BUSINESS_ADDRESS_DELETE = 'Business address has been deleted successfully.';
  public static SUCCESS_ACCOUNT_ADDRESS_DEFAULT = 'Default address has been updated successfully.';
  public static SUCCESS_MESSAGE_RESEND_OTP = 'Verification code has been resent.';
  public static CONFIRMATION_MESSAGE_FOR_SUSPEND_ACCOUNT = 'Are you sure you want to suspend this account?';
  public static SUCCESS_MESSAGE_ACCOUNT_SUSPEND = 'Account has been suspended successfully.';
  public static CONFIRMATION_MESSAGE_FOR_RESUME_ACCOUNT = "Are you sure you want to resume this account?";
  public static SUCCESS_MESSAGE_ACCOUNT_RESUME = 'Account has been resumed successfully.';
  public static SUCCESS_MESSAGE_OF_ACCOUNT_ADDED = 'Account has been added successfully.';
  public static SUCCESS_MESSAGE_OF_ACCOUNT_UPDATE = 'Account has been updated successfully.';
  public static CONFIRMATION_MESSAGE_BUSINESS_ADDRESS_DELETE = 'Are you sure you want to delete this business address?';
  public static CONFIRMATION_MESSAGE_HOME_ADDRESS_DELETE = 'Are you sure you want to delete this home address?';
  public static CONFIRMATION_MESSAGE_CONTACT_SUSPEND = 'Are you sure you want to suspend this contact?';
  public static CONFIRMATION_MESSAGE_CONTACT_DECLINED = 'Are you sure you want to declined this contact?';
  public static CONFIRMATION_MESSAGE_CONTACT_DELETE = 'Are you sure you want to delete this contact?';
  public static SUCCESS_MESSAGE_OF_CONTACT_SUSPEND = 'Contact has been suspended successfully.';
  public static SUCCESS_MESSAGE_OF_CONTACT_DECLINED = 'Contact has been declined successfully.';
  public static SUCCESS_MESSAGE_OF_CONTACT_DELETED = 'Contact has been deleted successfully.';
  public static CONFIRMATION_MESSAGE_OF_CONTACT_RESUME = 'Are you sure you want to resume this contact?';
  public static CONFIRMATION_MESSAGE_OF_CONTACT_ACTIVE = 'Are you sure you want to active this contact?';
  public static SUCCESS_MESSAGE_OF_CONTACT_RESUME = 'Contact has been resumed successfully.';
  public static SUCCESS_MESSAGE_OF_CONTACT_ACTIVE = 'Contact has been active successfully.';
  public static SUCCESS_MESSAGE_OF_CONTACT_ADDED = 'Contact has been added successfully.';
  public static SUCCESS_MESSAGE_OF_CONTACT_UPDATE = 'Contact has been updated successfully.';
  public static CONFIRMATION_MESSAGE_OF_APPROVE_ACCOUNT = 'Are you sure you want to approve this account?';
  public static SUCCESS_MESSAGE_OF_ACCOUNT_APPROVE = 'Account has been approved successfully.';
  public static ERROR_MESSAGE_SELECT_ASSIGN_USER = 'Please select a sales representative before approving the contact'
  public static SUCCESS_MESSAGE_OF_PRODUCT_SHEET_DATA_UPLOADED = 'Product has been updated successfully.';
  public static CONFIRMATION_MESSAGE_OF_MAKE_PRODUCT_AS_FEATURE = "Are you sure you want to add this product as Feature product?";
  public static SUCCESS_MESSAGE_OF_FEATURE_PRODUCT = 'Product has been add as Feature product.';
  public static CONFIRMATION_MESSAGE_OF_MAKE_PRODUCT_AS_ALL_IN_ONE = 'Are you sure you want add this product as All in one?';
  public static SUCCESS_MESSAGE_OF_PRODUCT_AS_ALL_IN_ONE = 'Product has been add as All in one.';
  public static CONFIRMATION_MESSAGE_OF_REMOVE_PRODUCT_FROM_FEATURE = 'Are you sure you want to remove this product as Feature product?';
  public static SUCCESS_MESSAGE_OF_REMOVE_PRODUCT_FROM_FEATURE = 'Product has been remove from Feature product.';
  public static CONFIRMATION_MESSAGE_OF_REMOVE_FROM_ALL_IN_ONE = 'Are you sure you want add this product as All in one?';
  public static SUCCESS_MESSAGE_OF_REMOVE_PRODUCT_FROM_ALL_IN_ONE = 'Product has been remove from All in one.';
  public static SUCCESS_MESSAGE_OF_PRODUCT_IMAGE_UPDATED = 'Product image order has been updated successfully.';
  public static SUCCESS_MESSAGE_OF_ACCOUNT_LOGO_UPLOADED = 'Account logo has been uploaded successfully.';
  public static SUCCESS_MESSAGE_RESET_PASSWORD = "Resent password mail has been sent to user.";
  public static SUCCESS_MESSAGE_SAVE_SETTING = "Setting data has been update successfully.";
  public static CONFIRM_DELETE_USER = 'Are you sure you want to delete this user?';
  public static SUCCESS_MESSAGE_DELETE_USER = 'User has been deleted successfully.';
  public static SUCCESS_MESSAGE_SAVE_USER = 'User has been added successfully.';
  public static CONFIRMATION_CART_PRODUCT_EXIST_TITLE = 'Alert';
  public static SUCCESS_MESSAGE_ADD_QUANTITY = "This product already exist in your quote. Would you like to increase the total qty?";
  public static CONFIRM_DELETE_CARRIER = 'Are you sure you want to delete this carrier?';
  public static SUCCESS_MESSAGE_DELETE_CARRIER = 'Carrier has been deleted successfully.';
  public static SUCCESS_MESSAGE_CONTACT_ADDRESS_ADDED = 'Contact address has been added successfully.';
  public static SUCCESS_MESSAGE_CONTACT_ADDRESS_UPDATED = 'Contact address has been updated successfully.';
  public static SUCCESS_MESSAGE_HOME_ADDRESS_DELETE = 'Home address has been deleted successfully.';
  public static SUCCESS_MESSAGE_CONTACT_ADDRESS_DEFAULT = 'Default address has been updated successfully.';

  public static SUCCESS_MESSAGE_ADD_QUANTITY_FOR_ACCESSORIES(profileName: String) {
    return `The product accessories ${profileName} already exist in your quote. Would you like to increase the total QTY?`
  };

  public static UNABLE_COMPLETED_ORDER_STATUS_CHANGE = 'You are unable to update the order status. This order is already completed.';
  public static UNABLE_ORDER_STATUS_CHANGE = 'You are unable to update the order status. This quote is not accepted.';
  public static UNABLE_COMPLETED_QUOTE_STATUS_CHANGE = 'You are unable to update the quote status. This quote is already completed.';
  public static UNABLE_COMPLETED_SAMPLE_ORDER_STATUS_CHANGE = 'You are unable to update the sample order status. This sample order is already completed.';
  public static UNABLE_QUOTE_STATUS_CHANGE = 'You are unable to update the quote status. This quote is not accepted.';
  public static SUCCESS_MESSAGE_DELIVERY_ADDRESS_UPDATED = 'Delivery address has been updated successfully.';
  public static SUCCESS_MESSAGE_LOGISTIC_DETAIL_UPDATED = 'Logistic detail has been updated successfully.';
  public static UNABLE_QUOTE_STATUS_CHANGE_WITHOUT_DELIVERY = 'Please add delivery details to update quote status.';
  public static UNABLE_QUOTE_DETAIL_CHANGE = 'The quote is archived, please unarchive first.';
  public static UNABLE_ORDER_DETAIL_CHANGE = 'The order is archived, please unarchive first.';
  public static UNABLE_SAMPLE_ORDER_DETAIL_CHANGE = 'The sample request is archived, please unarchive first.';
  public static UNABLE_QUOTE_STATUS_CHANGE_WITH_NO_DELIVERY_CHARGE = 'You are unable to update the quote status because the quote has no delivery charge added.';
  public static UNABLE_ORDER_STATUS_CHANGE_WITHOUT_JOB_CONFIRMATION = 'You are unable to change the order status due to the job confirmation is pending.';
  public static UNABLE_QUOTE_STATUS_CHANGE_WITH_DRAFT_STATUS = 'This quote is saved as a draft, please create a quote first.';
  public static UNABLE_QUOTE_STATUS_CHANGE_WITH_EXPIRED = 'This quote is expired, please reactivate it first.';
  public static UNABLE_RESET_PASSWORD_LINK = 'You are unable to send reset password link due to the contact is not active.';
  public static UNABLE_CREATE_SAMPLE_REQUEST = 'You are unable to create sample request due to the contact is not active.';
  public static UNABLE_CREATE_QUOTE = 'You are unable to create quote due to the contact is not active.';
  public static UNABLE_SAMPLE_ORDER_STATUS_CHANGE_WITHOUT_PACKING_IMAGE = 'You are unable to update the status of the sample request because packing images are not available for all products.';

  // Placeholder
  public static INPUT_PLACEHOLDER = 'Search';
  public static DROPDOWN_PLACEHOLDER = 'Select';
  public static ROLE_ADMIN = 'Admin';
  public static DROPDOWN_FILTER_BY_TYPE = 'Filter by type';
  public static DOWNLOAD_TYPE = 'Export';

  // Order type
  public static ORDER_TYPE_QUOTE = 'Quote';
  public static ORDER_TYPE_ORDER = 'Order';
  public static ORDER_TYPE_SAMPLE = 'Sample';

  public static ORDER_TYPE_QUOTE_SHORT = 'Q';
  public static ORDER_TYPE_ORDER_SHORT = 'O';
  public static ORDER_TYPE_SAMPLE_SHORT = 'SO';
  public static ORDER_TYPE_ACCOUNT_SHORT = 'AC';
  public static ORDER_TYPE_CONTACT_SHORT = 'C';
  public static ORDER_TYPE = 'S';

  public static FORK_TRUCK = 'Fork Truck';
  public static PRODUCT_RESOURCE_DOWNLOAD_SORT = 'PSR';
  public static TRUCK_TYPE_CLIENT_ORGANIZED = 'Client Organized';


  //Quote status
  // public static QUOTE_PENDING_SHORT = 'Q';
  // public static QUOTE_ACCEPT_SHORT = 'A';
  // public static QUOTE_SHIPPING_REQUIRED_SHORT = 'SR';
  // public static QUOTE_SHIPPING_PENDING_APPROVAL_SHORT = 'SPA';
  // public static QUOTE_SHIPPING_APPROVED_SHORT = 'SA';
  // public static QUOTE_FOLLOW_UP_SHORT = 'FU';
  // public static QUOTE_DEPOSIT_PENDING_SHORT = 'DP';
  // public static QUOTE_DEPOSIT_PAID_SHORT = 'DPD';
  // public static QUOTE_EXPIRED_SHORT = 'EXP';
  // public static QUOTE_ARCHIVED_SHORT = 'QA';

  public static QUOTE_CREATED_SHORT = 'QC';
  public static QUOTE_ACCEPTED_SHORT = 'QA';
  public static QUOTE_MANUAL_DELIVERY_QUOTE_REQUIRED_SHORT = 'MDQR';
  public static QUOTE_MANUAL_DELIVERY_QUOTE_PENDING_SHORT = 'MDQP';
  public static QUOTE_MANUAL_DELIVERY_QUOTE_ADDED_SHORT = 'MDQAD';
  public static QUOTE_MANUAL_DELIVERY_QUOTE_APPLIED_SHORT = 'MDQAP';
  // public static QUOTE_FOLLOW_UP_SHORT = 'FU';
  public static QUOTE_DEPOSIT_PAYMENT_DUE_SHORT = 'DPDU';
  public static QUOTE_DEPOSIT_PAID_SHORT = 'DPD';
  public static QUOTE_EXPIRED_SHORT = 'EXP';
  public static QUOTE_ARCHIVED_SHORT = 'QARCH';
  public static QUOTE_AUTO_DELIVERY_QUOTE_APPLIED_SHORT = 'ADQAP';
  public static QUOTE_ORDER_CREATED_SHORT = 'OC';
  public static QUOTE_DRAFT_SHORT = 'DFT';
  public static QUOTE_DEPOSIT_PAID_ID = 6;
  public static CLIENT_ORGANIZED_ORDER_COLLECTION_SHORT = 'COOC';
  public static QUOTE_DEPOSIT_PAYMENT_PROCESSING_SHORT = 'DPPROC';
  public static QUOTE_FINAL_PAYMENT_PROCESSING_SHORT = 'FPPROC';
  public static QUOTE_FULL_PAYMENT_PROCESSING_SHORT = 'FUPPROC';
  public static QUOTE_EXPIRY_IMMANENT_SHORT = 'QEI';
  public static QUOTE_DEPOSIT_EXPIRY_IMMANENT_SHORT = 'DEI';
  public static QUOTE_REACTIVATED_SHORT = 'QRA';
  public static QUOTE_CANCELLED_SHORT = 'QCAN';
  public static QUOTE_DELIVERY_ADDRESS_REQUIRED_SHORT = 'DAR';

  // Order status
  public static ORDER_BAD_DEBT_SHORT = 'OBD';
  public static ORDER_RECEIVED_SHORT = 'OR';
  public static ORDER_IN_PRODUCTION_SHORT = 'IP';
  public static ORDER_IN_TRANSIT_SHORT = 'IT';
  public static ORDER_DOMESTIC_STOCK_TRANSFER_SHORT = 'DST';
  public static ORDER_DELAYED_IN_TRANSIT_SHORT = 'DIT';
  public static ORDER_CLEARED_CUSTOMS_SHORT = 'CC';
  public static ORDER_PACKING_SHORT = 'AAW';
  public static ORDER_FINAL_PAYMENT_PENDING_SHORT = 'FPP';
  public static ORDER_FINAL_PAYMENT_PAID_SHORT = 'FPPD';
  public static ORDER_FULL_PAYMENT_PAID_SHORT = 'FUPP';
  public static ORDER_GOODS_DISPATCHED_UNSPECIFIED_SHORT = 'GDUNS';
  public static ORDER_GOODS_DISPATCHED_OUT_TO_TENDER_SHORT = 'GDOTT';
  public static ORDER_GOODS_DISPATCHED_SHORT = 'GD';
  public static ORDER_GOODS_DISPATCHED_ACCEPTED_SHORT = 'GDA';
  public static ORDER_GOODS_DISPATCHED_DECLINED_SHORT = 'GDD';
  public static ORDER_GOODS_DISPATCHED_CLIENT_ORGANIZED_SHORT = 'GDCO';
  public static ORDER_GOODS_DISPATCHED_DELIVERD_SHORT = 'GDDE';
  public static ORDER_COMPLETED_SHORT = 'C';
  public static ORDER_CANCELLED_SHORT = 'CAN';
  public static ORDER_FOLLOW_UP_SHORT = 'FU';
  public static ORDER_GOODS_DELIVERED_SHORT = 'GDDE';
  public static ORDER_NOMINATE_DELIVERY_DATE = 'ONDD';
  public static ORDER_FINAL_PAYMENT_PROCESSING = 'FPPROC';
  public static ORDER_DELIVERY_SEQUENCE_SHORT = 'DSEQ';

  public static ORDER_CANCELLED_ID = 50;
  public static ORDER_DELAYED_IN_TRANSIT_ID = 53;
  public static PAYMENT_COMPLETE_SHORT = 'C';

  // Sample status
  public static SAMPLE_COMPLETE_SHORT = 'C';
  public static SAMPLE_FOLLOW_UP_SHORT = 'FU';

  // Carrier status id
  public static CARRIER_STATUS_UNSPECIFIED_ID = 1;
  public static CARRIER_STATUS_OUT_TO_TENDER_ID = 2;
  public static CARRIER_STATUS_DESPATCHED_ID = 3;
  public static CARRIER_STATUS_ACCEPTED_ID = 4;
  public static CARRIER_STATUS_DECLINED_ID = 5;
  public static CARRIER_STATUS_CLIENT_ORGANIZED_ID = 6;
  public static CARRIER_STATUS_DELIVERD_ID = 7;

  // Sample Order status
  public static SAMPLE_ORDER_REQUESTED_SHORT = 'OR';
  public static SAMPLE_ORDER_PACKING_SHORT = 'PC';
  public static SAMPLE_ORDER_DISPATCHED_SHORT = 'OIW';
  public static SAMPLE_ORDER_DELIVERED_SHORT = 'D';
  public static SAMPLE_ORDER_FOLLOW_UP_SHORT = 'FU';
  public static SAMPLE_ORDER_COMPLETED_SHORT = 'C';

  public static QUOTE_WAREHOUSE_DATE_UPDATED = 'Ware house date has been set successfully.';
  public static ORDER_STOCK_REQUIRED_DATE_SET = 'The stock required date has been set successfully.';
  public static ORDER_STOCK_REQUIRED_DATE_UPDATED = 'The stock required date has been updated successfully.';
  public static ORDER_NOMINATED_DELIVERY_DATE_UPDATED = 'Nominated delivery date has been set successfully.';
  public static ORDER_CARRIER_STATUS_UPDTAED = 'Carrier status has been updated successfully.';
  public static QUOTE_STATUS_CHANGE_SUCCESS = 'Quote status has been updated successfully.';
  public static ORDER_STATUS_CHANGE_SUCCESS = 'Order status has been updated successfully.';
  public static PAYMENT_STATUS_CHANGE_SUCCESS = 'Payment status has been updated successfully.';
  public static SAMPLE_STATUS_CHANGE_SUCCESS = 'Sample status has been updated successfully.';
  public static SAMPLE_REQUEST_STATUS_CHANGE_SUCCESS = 'Sample request status has been updated successfully.';
  public static ORDER_WAREHOUSE_STATUS_UPDTAED = 'Warehouse status has been updated successfully.';

  public static LINK_QUOTES_ORDERS_SUCCESS_MESSAGE = 'Quotes/orders has been linked successfully';
  public static ANITICIPATED_WAREHOUSE_DATE_UPDATED_SUCCESS = 'Anticipated warehouse arrival date has been updated successfully.';
  public static ANITICIPATED_PRODUCTION_COMPLETION_DATE_UPDATED_SUCCESS = 'Anticipated production completion date has been updated successfully.';
  public static GOODS_DISPATCHED_ORDER_ARRAY = [
    {
      // id :'28',
      name: 'Goods Despatch Unassigned',
      color: '#D90008',
      status: ['GDUNS'],
      label_class: 'goods-despatch-unspecified-flag',
      disable_class: 'goods-despatched-unspecified-disable',
      background: '#D90008'
    },
    {
      name: 'Goods Despatch Out To Tender',
      color: '#FF7600',
      status: ['GDOTT'],
      label_class: 'goods-despatch-out-to-tender-flag',
      // disable_class: 'goods-despatched-out-to-tender-disable',
      disable_class: '',
      background: '#FF7600'
    },
    {
      name: 'Goods Despatch Accepted',
      color: '#499D63',
      status: ['GDA'],
      label_class: 'goods-despatch-accepted-flag',
      disable_class: 'goods-despatched-accepted-disable',
      background: '#499D63'
    },
    {
      name: 'Goods Despatch Declined',
      color: '#D90008',
      status: ['GDD'],
      label_class: 'goods-despatch-declined-flag',
      disable_class: 'goods-despatched-declined-disable',
      background: '#D90008'
    },
    {
      // id: '15',
      name: 'Goods Despatched',
      color: '#499D63',
      status: ['GD'],
      label_class: 'goods-despatched-flag',
      disable_class: 'goods-despatched-confirmed-disable',
      background: '#499D63'
    },
    {
      // id :'37',
      name: 'Goods Despatch Client Organized',
      color: '#499D63',
      status: ['GDCO'],
      class: 'goods-despatched-client-organized-flag',
      label_class: 'goods-despatched-client-organized-flag',
      email_template_key: [],
      disable_class: 'goods-despatched-client-organized-disable',
      background: '#499D63'
    },
    {
      // id :'40',
      name: 'Goods Delivered',
      color: '#dfbe74',
      status: ['GDDE'],
      label_class: 'goods-despatch-delivered-flag',
      disable_class: 'goods-despatched-delivered-disable',
      background: '#dfbe74'
    },
  ];

  public static FINAL_PAYMENT_ORDER_ARRAY = [
    {
      name: 'Final Payment Outstanding',
      color: '#D90008',
      status: ['FPP'],
      label_class: 'final-payment-flag',
      disable_class: 'final-payment-pending-disable',
      background: '#D90008'
    },
    {
      name: 'Final Payment Paid',
      class: 'received',
      status: ['FPPD', 'FUPP'],
      label_class: 'final-payment-paid-flag',
      disable_class: 'final-payment-paid-disable',
      background: '#499D63',
      color: '#499D63'
    },
  ];

  public static ORDER_STATUS_ARRAY = [
    {
      name: 'Order Received',
      class: 'received',
      status: ['OR'],
      label_class: 'recived-flag',
      email_template_key: ['order-received', 'deposit-paid'],
      disable_class: 'order-received-disable',
      color: '#9a5fa8',
      background: '#9a5fa8'
    },
    {
      name: 'In Production',
      color: '#4EBAB6',
      status: ['IP'],
      label_class: 'production-flag',
      disable_class: 'in-production-disable',
      background: '#4EBAB6'
    },
    {
      name: 'In Transit',
      class: 'transit',
      status: ['IT'],
      label_class: 'transit-flag',
      email_template_key: ['in-transit'],
      disable_class: 'in-transit-disable',
      color: '#B98000',
      background: '#B98000'
    },
    {
      name: 'Customs Held',
      color: '#ff7600',
      status: ['CC'],
      label_class: 'cleared-flag',
      disable_class: 'custom-held-disable',
      background: '#ff7600'
    },
    {
      name: 'Available At Warehouse',
      class: 'packing',
      status: ['AAW', 'ONDD'],
      label_class: 'packaging-flag',
      email_template_key: ['stock-landed', 'nominate-delivery-date'],
      disable_class: 'available-at-warehouse-disable',
      // disable_class: '',
      color: '#6d709a',
      background: '#6d709a'
    },
    {
      name: 'Final Payment Outstanding',
      color: '#D90008',
      status: ['FPP'],
      label_class: 'final-payment-flag',
      email_template_key: ['final-payment-reminder'],
      disable_class: 'final-payment-pending-disable',
      background: '#D90008'
    },
    {
      name: 'Final Payment Paid',
      class: 'received',
      status: ['FPPD', 'FUPP'],
      label_class: 'final-payment-paid-flag',
      email_template_key: [], // 'nominate-delivery-date'
      disable_class: 'final-payment-paid-disable',
      background: '#499D63',
      color: '#499D63'
    },
    {
      name: 'Goods Despatch Unassigned',
      color: '#D90008',
      status: ['GDUNS'],
      class: 'goods-despatch-unspecified-flag',
      label_class: 'goods-despatch-unspecified-flag',
      email_template_key: [],
      disable_class: 'goods-despatched-unspecified-disable',
      background: '#D90008'
    },
    {
      name: 'Goods Despatch Out To Tender',
      color: '#FF7600',
      status: ['GDOTT'],
      class: 'goods-despatch-out-to-tender-flag',
      label_class: 'goods-despatch-out-to-tender-flag',
      email_template_key: ['trade-user-carrier-added'],
      disable_class: '',
      //disable_class: 'goods-despatched-out-to-tender-disable',
      background: '#FF7600'
    },
    {
      name: 'Goods Despatch Accepted',
      color: '#499D63',
      status: ['GDA'],
      label_class: 'goods-despatch-accepted-flag',
      disable_class: 'goods-despatched-accepted-disable',
      background: '#499D63'
    },
    {
      name: 'Goods Despatch Declined',
      color: '#D90008',
      status: ['GDD'],
      label_class: 'goods-despatch-declined-flag',
      disable_class: 'goods-despatched-declined-disable',
      background: '#D90008'
    },
    {
      name: 'Goods Despatch Despatched',
      color: '#499D63',
      status: ['GD'],
      class: 'goods-despatched-flag',
      label_class: 'goods-despatched-flag',
      email_template_key: ['order-dispatched'],
      disable_class: 'goods-despatched-confirmed-disable',
      background: '#499D63'
    },
    {
      name: 'Goods Despatch Delivered',
      color: '#499D63',
      status: ['GDDE'],
      class: 'goods-despatch-delivered-flag',
      label_class: 'goods-despatch-delivered-flag',
      disable_class: 'goods-despatch-delivered-disable',
      background: '#499D63'
    },
    {
      name: 'Goods Despatch Client Organized',
      color: '#499D63',
      status: ['GDCO'],
      class: 'goods-despatched-client-organized-flag',
      label_class: 'goods-despatched-client-organized-flag',
      email_template_key: [],
      disable_class: 'goods-despatched-client-organized-disable',
      background: '#499D63'
    },
    {
      name: 'Delayed In Transit',
      color: '#499D63',
      status: ['DIT'],
      class: 'goods-despatched-client-organized-flag',
      label_class: 'goods-despatched-client-organized-flag',
      email_template_key: [],
      disable_class: 'goods-despatched-client-organized-disable',
      background: '#499D63'
    },
    // {
    //     name: 'Goods Despatched',
    //     color: '#B4BE3C',
    //     status: ['GD'],
    //     label_class: 'dispatched-flag',
    //     email_template_key: ['order-dispatched'],
    //     disable_class: 'goods-despatched-disable'
    // },
    {
      name: 'Follow Up',
      color: '#d51ed2',
      class: 'followup',
      status: ['FU'],
      label_class: 'followup-flag',
      email_template_key: [],
      disable_class: 'follow-up-disable',
      background: '#d51ed2'
    },
    {
      name: 'Complete',
      class: 'complete',
      status: ['C'],
      label_class: 'completed-flag',
      email_template_key: ['order-complete'],
      disable_class: 'complete-disable',
      color: '#222324',
      background: '#222324'
    },
    {
      name: 'Archived',
      class: '',
      status: ['QARCH'],
      label_class: '',
      email_template_key: [],
      color: '',
      background: ''
    },
    // {
    //     name: 'Bad Debt',
    //     class: '',
    //     status: ['OBD'],
    //     label_class: '',
    //     email_template_key: [],
    //     color: '',
    //     background: ''
    // },
  ];

  public static QUOTE_STATUS_IN_ZONE_ARRAY = [
    // {name: 'Quote', class: 'quote', status: ['Q'], label_class: 'quote-flag', disable_class: 'quote-disable'},
    // {
    //     name: 'Follow Up',
    //     color: '#6D709A',
    //     status: ['FU'],
    //     label_class: 'followup-flag',
    //     disable_class: 'follow-up-disable'
    // },
    // {
    //     name: 'Deposit Payment Pending',
    //     color: '#3D95ED',
    //     status: ['DP'],
    //     label_class: 'pending-flag',
    //     disable_class: 'deposit-pending-disable'
    // },
    // {
    //     name: 'Expired',
    //     color: '#D1102E',
    //     status: ['EXP'],
    //     label_class: 'expired-flag',
    //     disable_class: 'expired-disable'
    // },
    {
      name: 'Quote Created',
      class: 'quote',
      status: ['QC'],
      label_class: 'quote-flag',
      disable_class: 'quote-disable',
      color: '#9A5FA8',
      background: '#9A5FA8'
    },
    {
      name: 'Auto Delivery Quote Applied',
      color: '#499D63',
      class: 'auto-delivery-quote-applied',
      status: ['ADQAP'],
      label_class: 'applied-flag',
      disable_class: 'applied-disable',
      background: '#499D63'
    },
    {
      name: 'Follow Up',
      color: '#d51ed2',
      class: 'followup',
      status: ['FU'],
      label_class: 'followup-flag',
      disable_class: 'follow-up-disable',
      background: '#d51ed2'
    },
    {
      name: 'Quote Accepted',
      color: '#6d709a',
      class: 'quote-accepted',
      status: ['QA'],
      label_class: 'quote-accepted-flag',
      disable_class: 'quote-accepted-disable',
      background: '#6d709a'
    },
    {
      name: 'Deposit Payment Due',
      // color: '#3D95ED',
      class: 'deposit-payment-due',
      status: ['DPDU'],
      label_class: 'pending-flag',
      disable_class: 'deposit-pending-disable',
      background: '#d90008',
      color: '#d90008'
    },
    // {
    //     name: 'Order Created',
    //     color: '#7A8591',
    //     class: 'order-created',
    //     status: ['OC'],
    //     label_class: 'order-created-flag',
    //     disable_class: 'order-created-disable',
    //     background: '#7A8591'
    // },
    {
      name: 'Expired',
      class: 'expired',
      color: '#D42A3B',
      status: ['EXP'],
      label_class: 'expired-flag',
      disable_class: 'expired-disable',
      background: '#D42A3B'
    }
  ];

  public static MANUAL_DELIVERY_QUOTE_ARRAY = [
    {
      name: 'Manual Delivery Quote Required',
      color: '#D42A3B',
      status: ['MDQR'],
      label_class: 'pending-flag',
      disable_class: 'pending-disable',
      background: '#D42A3B'
    },
    {
      name: 'Delivery Address Required',
      color: '#D42A3B',
      status: ['DAR'],
      label_class: 'delivery-address-required-flag',
      disable_class: 'delivery-address-required-disable',
      background: '#D42A3B'
    },
    {
      name: 'Manual Delivery Quote Added',
      color: '#499D63',
      status: ['MDQAD'],
      label_class: 'added-flag',
      disable_class: 'added-disable',
      background: '#499D63'
    },
    {
      name: 'Manual Delivery Quote Applied',
      color: '#499D63',
      status: ['MDQAP'],
      label_class: 'applied-flag',
      disable_class: 'applied-disable',
      background: '#499D63'
    }
  ];

  public static QUOTE_STATUS_OUT_ZONE_ARRAY = [
    {
      name: 'Quote Created',
      class: 'quote',
      status: ['QC'],
      label_class: 'quote-flag',
      disable_class: 'quote-disable',
      color: '#9A5FA8',
      background: '#9A5FA8'
    },
    {
      name: 'Delivery Address Required',
      class: 'delivery-address-required',
      status: ['DAR'],
      label_class: 'delivery-address-required-flag',
      disable_class: 'delivery-address-required-disable',
      color: '#D42A3B',
      background: '#D42A3B'
    },
    {
      name: 'Manual Delivery Quote Required',
      class: 'manual-delivery-quote-required',
      status: ['MDQR'],
      label_class: 'manual-delivery-quote-pending-flag',
      disable_class: 'pending-disable',
      background: '#D42A3B',
      color: '#D42A3B'
    },
    {
      name: 'Manual Delivery Quote Added',
      class: 'manual-delivery-quote-added',
      status: ['MDQAD'],
      label_class: 'added-flag',
      disable_class: 'added-disable',
      background: '#499D63',
      color: '#499D63'
    },
    {
      name: 'Manual Delivery Quote Applied',
      color: '#499D63',
      class: 'manual-delivery-quote-applied',
      status: ['MDQAP'],
      label_class: 'applied-flag',
      disable_class: 'applied-disable',
      background: '#499D63'
    },
    {
      name: 'Follow Up',
      color: '#d51ed2',
      class: 'followup',
      status: ['FU'],
      label_class: 'followup-flag',
      disable_class: 'follow-up-disable',
      background: '#d51ed2'
    },
    {
      name: 'Quote Accepted',
      // color: '#6D709A',
      class: 'quote-accepted',
      status: ['QA'],
      label_class: 'quote-accepted-flag',
      disable_class: 'quote-accepted-disable',
      color: '#6d709a',
      background: '#6d709a'
    },
    {
      name: 'Deposit Payment Due',
      // color: '#3D95ED',
      class: 'deposit-payment-due',
      status: ['DPDU'],
      label_class: 'pending-flag',
      disable_class: 'deposit-pending-disable',
      background: '#3D95ED',
      color: '#3D95ED',
    },
    // {
    //     name: 'Order Created',
    //     color: '#7A8591',
    //     class: 'order-created',
    //     status: ['OC'],
    //     label_class: 'order-created-flag',
    //     disable_class: 'order-created-disable',
    //     background: '#7A8591'
    // },
    {
      name: 'Expired',
      class: 'expired',
      color: '#D42A3B',
      status: ['EXP'],
      label_class: 'expired-flag',
      disable_class: 'expired-disable',
      background: '#D42A3B'
    }
  ];

  public static QUOTE_STATUS_ARRAY = [
    // {name: 'Quote', class: 'quote', status: ['Q'], label_class: 'quote-flag'},
    // {name: 'Shipping Quote Required', color: '#D1102E', status: ['SR'], label_class: 'pending-flag'},
    // {name: 'Shipping Pending Approval', color: '#D1102E', status: ['SPA'], label_class: 'pending-flag'},
    // {name: 'Follow Up', color: '#6D709A', status: ['FU'], label_class: 'follow-up-flag'},
    // {
    //     name: 'Deposit Payment Pending',
    //     class: '#3D95ED',
    //     status: ['DP'],
    //     label_class: 'deposit-payment-pending-flag'
    // },
    // {name: 'Expired', color: '#D1102E', class: '#3D95ED', status: ['EXP'], label_class: 'pending-flag'},
    {
      name: 'Quote Created', class: 'quote', status: ['QC'], label_class: 'quote-flag', color: '#9A5FA8',
      background: '#9A5FA8'
    },
    {
      name: 'Draft', class: 'quote-draft', status: ['DFT'], label_class: 'quote-draft-flag', color: '#9A5FA8',
      background: '#229F9F'
    },
    {
      name: 'Manual Delivery Quote Required',
      status: ['MDQR'],
      label_class: 'pending-flag',
      background: '#D42A3B',
      color: '#D42A3B'
    },
    {
      name: 'Manual Delivery Quote Added', status: ['MDQAD'], label_class: 'added-flag', background: '#499D63',
      color: '#499D63'
    },
    {
      name: 'Manual Delivery Quote Applied',
      status: ['MDQAP'],
      label_class: 'applied-flag',
      background: '#499D63',
      color: '#499D63'
    },
    {
      name: 'Auto Delivery Quote Applied',
      status: ['ADQAP'],
      label_class: 'applied-flag',
      background: '#499D63',
      color: '#499D63'
    },
    {name: 'Follow Up', status: ['FU'], label_class: 'follow-up-flag', background: '#d51ed2', color: '#d51ed2'},
    {
      name: 'Quote Accepted',
      color: '#6d709a',
      status: ['QA'],
      label_class: 'quote-accepted-flag',
      background: '#6d709a'
    },
    {
      name: 'Deposit Payment Due',
      color: '#d90008',
      status: ['DPDU'],
      label_class: 'deposit-payment-pending-flag',
      background: '#d90008'
    },
    // {
    //     name: 'Order Created',
    //     color: '#7A8591',
    //     status: ['OC'],
    //     label_class: 'order-created-flag',
    //     background: '#7A8591'
    // },
    {
      name: 'Expired',
      color: '#D42A3B',
      class: '#3D95ED',
      status: ['EXP'],
      label_class: 'pending-flag',
      background: '#D42A3B'
    },
    {name: 'Archived', color: '#919192', status: ['QARCH'], label_class: 'archive-flag', background: 'none'}
  ];

  public static QUOTE_ORDER_STATUS_ARRAY = [
    {
      name: 'Quote Created',
      class: 'quote',
      status: ['QC'],
      label_class: 'quote-flag',
      color: '#9A5FA8',
      background: '#9A5FA8'
    },
    {
      name: 'Draft', class: 'quote-draft', status: ['DFT'], label_class: 'quote-draft-flag', color: '#9A5FA8',
      background: '#229F9F'
    },
    {
      name: 'Manual Delivery Quote Required',
      color: '#499D63',
      status: ['MDQR'],
      label_class: 'manual-delivery-quote-required-flag',
      email_template_key: ['shipping-quote-required'],
      background: '499D63'
    },
    {
      name: 'Manual Delivery Quote Added',
      color: '#499D63',
      status: ['MDQAD'],
      label_class: 'manual-delivery-quote-added-flag',
      background: '#499D63'
    },
    {
      name: 'Manual Delivery Quote Applied',
      color: '#499D63',
      status: ['MDQAP'],
      label_class: 'manual-delivery-quote-applied-flag',
      background: '#499D63'
    },
    {
      name: 'Auto Delivery Quote Applied',
      color: '#499D63',
      status: ['ADQAP'],
      label_class: 'auto-delivery-quote-applied-flag',
      background: '#499D63'
    },
    {
      name: 'Follow Up',
      color: '#d51ed2',
      status: ['FU'],
      label_class: 'follow-up-flag',
      background: '#d51ed2'
    },
    {
      name: 'Quote Accepted',
      color: '#6d709a',
      status: ['QA'],
      label_class: 'quote-accepted-flag',
      background: '#6d709a'
    },
    {
      name: 'Deposit Payment Due',
      color: '#d90008',
      class: '#d90008',
      status: ['DPDU'],
      label_class: 'pending-flag',
      background: '#d90008'
    },
    // {
    //     name: 'Order Created',
    //     color: '#7A8591',
    //     class: '#3D95ED',
    //     status: ['OC'],
    //     label_class: 'order-created-flag',
    //     background: '#7A8591'
    // },
    {
      name: 'Expired',
      color: '#D1102E',
      class: '#3D95ED',
      status: ['EXP'],
      label_class: 'pending-flag',
      background: '#D42A3B'
    },
    {
      name: 'Order Received',
      class: 'received',
      status: ['OR'],
      label_class: 'recived-flag',
      email_template_key: ['order-received', 'deposit-paid'],
      color: '#499D63',
      background: '#499D63'
    },
    {
      name: 'In Production',
      color: '#4EBAB6',
      status: ['IP'],
      label_class: 'production-flag',
      background: '#4EBAB6'
    },
    {
      name: 'In Transit',
      class: 'transit',
      status: ['IT'],
      label_class: 'transit-flag',
      email_template_key: ['in-transit'],
      color: '#B98000',
      background: '#B98000'
    },
    {
      name: 'Customs Held',
      color: '#ff7600',
      status: ['CC'],
      label_class: 'cleared-flag',
      background: '#ff7600'
    },
    {
      name: 'Arrived At Warehouse',
      class: 'packing',
      status: ['AAW'],
      label_class: 'packaging-flag',
      email_template_key: ['stock-landed'],
      color: '#4ebab6',
      background: '#4ebab6'
    },
    {
      name: 'Final Payment Outstanding',
      color: '#D90008',
      status: ['FPP'],
      label_class: 'final-payment-flag',
      email_template_key: ['final-payment-reminder'],
      background: '#D90008'
    },
    {
      name: 'Final Payment Paid',
      class: 'received',
      status: ['FPPD', 'FUPP'],
      label_class: 'final-payment-paid-flag',
      email_template_key: ['nominate-delivery-date'],
      color: '#499D63',
      background: '#499D63'
    },
    {
      name: 'Goods Despatch Unassigned',
      color: '#D90008',
      status: ['GDUNS'],
      class: 'goods-despatch-unspecified-flag',
      label_class: 'goods-despatch-unspecified-flag',
      email_template_key: [],
      background: '#D90008'
    },
    {
      name: 'Goods Despatch Out To Tender',
      color: '#FF7600',
      status: ['GDOTT'],
      class: 'goods-despatch-out-to-tender-flag',
      label_class: 'goods-despatch-out-to-tender-flag',
      email_template_key: [],
      background: '#FF7600'
    },
    {
      name: 'Goods Despatch Accepted',
      color: '#FF7600',
      status: ['GDA'],
      label_class: 'goods-despatch-accepted-flag',
      disable_class: 'goods-despatched-accepted-disable',
      background: '#FF7600'
    },
    {
      name: 'Goods Despatch Declined',
      color: '#FF7600',
      status: ['GDD'],
      label_class: 'goods-despatch-declined-flag',
      disable_class: 'goods-despatched-declined-disable',
      background: '#FF7600'
    },
    {
      name: 'Goods Despatch Despatched',
      color: '#499D63',
      status: ['GD'],
      class: 'goods-despatched-flag',
      label_class: 'goods-despatched-flag',
      email_template_key: ['order-dispatched'],
      background: '#499D63'
    },
    {
      name: 'Goods Despatch Client Organized',
      color: '#499D63',
      status: ['GDCO'],
      class: 'goods-despatched-client-organized-flag',
      label_class: 'goods-despatched-client-organized-flag',
      email_template_key: [],
      disable_class: 'goods-despatched-client-organized-disable',
      background: '#499D63'
    },
    // {
    //     name: 'Goods Dispatched',
    //     color: '#B4BE3C',
    //     status: ['GD'],
    //     label_class: 'dispatched-flag',
    //     email_template_key: ['order-dispatched']
    // },
    {
      name: 'Complete',
      class: 'complete',
      status: ['C'],
      label_class: 'completed-flag',
      email_template_key: ['order-complete'],
      color: '#222324',
      background: '#222324'
    },
    {
      name: 'Follow Up',
      color: '#d51ed2',
      class: 'followup',
      status: ['FU'],
      label_class: 'followup-flag',
      email_template_key: [],
      background: '#d51ed2'
    }
  ];

  public static SAMPLE_QUOTE_ORDER_STATUS_ARRAY = [
    {
      name: 'Quote Created',
      class: 'quote',
      status: ['QC'],
      label_class: 'quote-flag',
      type: 'Q',
      color: '#9A5FA8',
      background: '#9A5FA8'
    },
    {
      name: 'Draft', class: 'quote-draft', status: ['DFT'], label_class: 'quote-draft-flag', color: '#9A5FA8',
      background: '#229F9F'
    },
    {
      name: 'Manual Delivery Quote Required',
      color: '#499D63',
      status: ['MDQR'],
      label_class: 'manual-delivery-quote-required-flag',
      email_template_key: ['shipping-quote-required'],
      type: 'Q',
      background: '#499D63'
    },
    {
      name: 'Manual Delivery Quote Added',
      color: '#499D63',
      status: ['MDQAD'],
      label_class: 'manual-delivery-quote-added-flag',
      type: 'Q',
      background: '#499D63'
    },
    {
      name: 'Manual Delivery Quote Applied',
      color: '#499D63',
      status: ['MDQAP'],
      label_class: 'manual-delivery-quote-applied-flag',
      type: 'Q',
      background: '#499D63'
    },
    {
      name: 'Auto Delivery Quote Applied',
      color: '#499D63',
      status: ['ADQAP'],
      label_class: 'auto-delivery-quote-applied-flag',
      type: 'Q',
      background: '#499D63'
    },
    {
      name: 'Awaiting Feedback',
      color: '#d51ed2',
      status: ['FU'],
      label_class: 'follow-up-flag',
      type: 'Q',
      background: '#d51ed2'
    },
    {
      name: 'Delayed In Transit',
      color: '#d51ed2',
      status: ['DIT'],
      label_class: 'follow-up-flag',
      type: 'Q',
      background: '#d51ed2'
    },
    {
      name: 'Quote Accepted',
      color: '#6d709a',
      status: ['QA'],
      label_class: 'quote-accepted-flag',
      type: 'Q',
      background: '#6d709a'
    },
    {
      name: 'Deposit Payment Due',
      color: '#d90008',
      class: '#d90008',
      status: ['DPDU'],
      label_class: 'pending-flag',
      type: 'Q',
      background: '#d90008'
    },
    {
      name: 'Quote Expiry Immanent',
      color: '#d90008',
      class: '#d90008',
      status: ['QEI'],
      label_class: 'pending-flag',
      type: 'Q',
      background: '#d90008'
    },

    {
      name: 'Deposit Expiry Immanent',
      color: '#d90008',
      class: '#d90008',
      status: ['DEI'],
      label_class: 'pending-flag',
      type: 'Q',
      background: '#d90008'
    },
    // {
    //     name: 'Order Created',
    //     color: '#7A8591',
    //     class: '#3D95ED',
    //     status: ['OC'],
    //     label_class: 'order-created-flag',
    //     type: 'Q',
    //     background: '#7A8591'
    // },
    {
      name: 'Expired',
      color: '#D42A3B',
      class: '#3D95ED',
      status: ['EXP'],
      label_class: 'expired-flag',
      type: 'Q',
      background: '#D42A3B'
    },
    {
      name: 'Order Received',
      class: 'received',
      status: ['OR'],
      label_class: 'recived-flag',
      email_template_key: ['order-received', 'deposit-paid'],
      type: 'O',
      color: '#9a5fa8',
      background: '#9a5fa8'
    },
    {
      name: 'In Production',
      color: '#4EBAB6',
      status: ['IP'],
      label_class: 'production-flag',
      type: 'O',
      background: '#4EBAB6'
    },
    {
      name: 'In Transit',
      class: 'transit',
      status: ['IT'],
      label_class: 'transit-flag',
      email_template_key: ['in-transit'],
      type: 'O',
      color: '#B98000',
      background: '#B98000'
    },
    {
      name: 'Customs Held',
      color: '#ff7600',
      status: ['CC'],
      label_class: 'cleared-flag',
      type: 'O',
      background: '#ff7600'
    },
    {
      name: 'Arrived At Warehouse',
      class: 'packing',
      status: ['AAW'],
      label_class: 'packaging-flag',
      email_template_key: ['stock-landed'],
      type: 'O',
      color: '#4ebab6',
      background: '#4ebab6'
    },
    {
      name: 'Final Payment Outstanding',
      color: '#D90008',
      status: ['FPP'],
      label_class: 'final-payment-flag',
      email_template_key: ['final-payment-reminder'],
      type: 'O',
      background: '#D90008'
    },
    {
      name: 'Final Payment Paid',
      class: 'received',
      status: ['FPPD', 'FUPP'],
      label_class: 'final-payment-paid-flag',
      email_template_key: ['nominate-delivery-date'],
      type: 'O',
      color: '#499D63',
      background: '#499D63'
    },
    {
      name: 'Goods Despatch Unassigned',
      color: '#D42A3B',
      status: ['GDUNS'],
      class: 'goods-despatch-unspecified-flag',
      label_class: 'goods-despatch-unspecified-flag',
      email_template_key: [],
      type: 'O',
      background: '#D90008'
    },
    {
      name: 'Goods Despatch Out To Tender',
      color: '#FF7600',
      status: ['GDOTT'],
      class: 'goods-despatch-out-to-tender-flag',
      label_class: 'goods-despatch-out-to-tender-flag',
      email_template_key: [],
      type: 'O',
      background: '#FF7600'
    },
    {
      name: 'Goods Despatch Accepted',
      color: '#FF7600',
      status: ['GDA'],
      label_class: 'goods-despatch-accepted-flag',
      disable_class: 'goods-despatched-accepted-disable',
      background: '#FF7600'
    },
    {
      name: 'Goods Despatch Declined',
      color: '#FF7600',
      status: ['GDD'],
      label_class: 'goods-despatch-declined-flag',
      disable_class: 'goods-despatched-declined-disable',
      background: '#FF7600'
    },
    {
      name: 'Goods Despatch Despatched',
      color: '#499D63',
      status: ['GD'],
      class: 'goods-despatched-flag',
      label_class: 'goods-despatched-flag',
      email_template_key: ['order-dispatched'],
      type: 'O',
      background: '#499D63'
    },
    {
      name: 'Goods Despatch Client Organized',
      color: '#499D63',
      status: ['GDCO'],
      class: 'goods-despatched-client-organized-flag',
      label_class: 'goods-despatched-client-organized-flag',
      email_template_key: [],
      type: 'O',
      background: '#499D63'
    },
    {
      name: 'Goods Delivered',
      color: '#dfbe74',
      status: ['GDDE'],
      label_class: 'goods-despatch-delivered-flag',
      disable_class: 'goods-despatched-delivered-disable',
      background: '#dfbe74'
    },
    // {
    //     name: 'Goods Dispatched',
    //     color: '#B4BE3C',
    //     status: ['GD'],
    //     label_class: 'dispatched-flag',
    //     email_template_key: ['order-dispatched']
    // },
    {
      name: 'Complete',
      class: 'complete',
      status: ['C'],
      label_class: 'completed-flag',
      email_template_key: ['order-complete'],
      type: 'O',
      color: '#222324',
      background: '#222324'
    },
    {
      name: 'Follow Up',
      color: '#d51ed2',
      class: 'followup',
      status: ['FU'],
      label_class: 'followup-flag',
      email_template_key: [],
      type: 'O',
      background: '#d51ed2'
    },
    {
      name: 'Samples Requested',
      class: 'received-sample',
      status: ['OR'],
      label_class: 'recived-flag-sample',
      disable_class: 'sample-request-disable',
      type: 'SO',
      color: '#9a5fa8',
      background: '#9a5fa8'
    },
    {
      name: 'Packaged',
      class: 'packing',
      status: ['PC'],
      label_class: 'packaging-flag',
      disable_class: 'package-disable',
      type: 'SO',
      color: '#4ebab6',
      background: '#4ebab6'
    },
    {
      name: 'Despatched',
      class: 'transit',
      status: ['OIW'],
      label_class: 'transit-flag',
      email_template_key: ['sample-dispatched'],
      disable_class: 'despatched-disable',
      type: 'SO',
      color: '#362e9b',
      background: '#362e9b'
    },
    {
      name: 'Delivered',
      class: 'complete',
      status: ['D'],
      label_class: 'completed-flag',
      email_template_key: ['sample-order-complete'],
      disable_class: 'delivered-disable',
      type: 'SO',
      color: '#222324',
      background: '#222324'
    },
    {
      name: 'Follow Up', class: 'followup', status: ['FU'], label_class: 'followup-flag',
      disable_class: 'follow-up-disable',
      type: 'SO',
      color: '#d51ed2',
      background: '#d51ed2'
    },
    {name: 'Archived', color: '#919192', status: ['QARCH'], label_class: 'archive-flag', background: 'none'}
  ];

  public static wareHouseArrivalValid = ['OR', 'DP', 'IP', 'IT', 'CC'];
  public static stockRequiredValid = ['OR', 'DP', 'IP', 'IT', 'CC', 'AAW', 'GDOTT'];
  public static editableValid = ['OR', 'DP', 'IP', 'IT', 'CC', 'AAW', 'GDOTT', 'FPP', 'FPPD', 'FUPP', 'GDOTT', 'GD', 'GD'];

  // public static SAMPLE_ORDER_STATUS = [
  //     {name: 'Order Received', class: 'received-sample', status: ['OR'], label_class: 'recived-flag'},
  //     {name: 'In Transit', class: 'transit', status: ['IT'], label_class: 'transit-flag'},
  //     {name: 'Complete', class: 'complete', status: ['C'], label_class: 'completed-flag'},
  //     {name: 'Packaged', class: 'packing', status: ['PC'], label_class: 'packaging-flag'}
  // ];
  //
  // public static GENERAL_USER_SAMPLE_ORDER_STATUS_ARRAY = [{
  //     name: 'Sample Order',
  //     class: 'quote',
  //     status: ['Q', 'A'],
  //     label_class: 'quote-flag'
  // },
  //     {name: 'Payment Pending', color: '#3D95ED', status: ['FPP'], label_class: 'recived-flag'},
  //     {name: 'Order Received', class: 'received', status: ['OR'], label_class: 'recived-flag'},
  //     {name: 'Arrived At Warehouse', class: 'packing', status: ['AAW'], label_class: 'packaging-flag'},
  //     {name: 'In Transit', class: 'transit', status: ['IT'], label_class: 'transit-flag'},
  //     {name: 'Complete', class: 'complete', status: ['C'], label_class: 'completed-flag'}];

  public static TRADE_SAMPLE_ORDER_STATUS_ARRAY = [
    {
      name: 'Samples Requested',
      class: 'received-sample',
      status: ['OR'],
      label_class: 'recived-flag-sample',
      disable_class: 'sample-request-disable',
      color: '#9a5fa8',
      background: '#9a5fa8'
    },
    {
      name: 'Packaged',
      class: 'packing',
      status: ['PC'],
      label_class: 'packaging-flag',
      disable_class: 'package-disable',
      color: '#4ebab6',
      background: '#4ebab6'
    },
    {
      name: 'Despatched',
      class: 'transit',
      status: ['OIW'],
      label_class: 'transit-flag',
      email_template_key: ['sample-dispatched'],
      disable_class: 'despatched-disable',
      color: '#362e9b',
      background: '#362e9b'
    },
    {
      name: 'Delivered',
      class: 'complete',
      status: ['D'],
      label_class: 'completed-flag',
      email_template_key: ['sample-order-complete'],
      disable_class: 'delivered-disable',
      color: '#dfbe74',
      background: '#dfbe74'
    },
    {
      name: 'Follow Up', class: 'followup', status: ['FU'], label_class: 'followup-flag',
      disable_class: 'follow-up-disable',
      color: '#d51ed2',
      background: '#d51ed2'
    },
    {
      name: 'Complete', class: 'complete', status: ['C'], label_class: 'complete-flag',
      disable_class: 'complete-disable',
      color: '#222324',
      background: '#222324'
    },
    {name: 'Archived', color: '#919192', status: ['QARCH'], label_class: 'archive-flag', background: 'none'}
  ];

  public static SAMPLE_STATUS_DISPATCHED = 'Despatched';
  public static SAMPLE_STATUS_DELIVERED = 'Delivered';
  public static SAMPLE_STATUS_FOLLOWUP = 'Follow Up';
  public static SAMPLE_STATUS_COMPLETE = 'Complete';

  public static ORDER_STATUS_DESPATCH_CONFIRMED = 'Goods Despatch Despatched';
  public static ORDER_STATUS_DESPATCHED = 'Goods Despatched';
  public static ORDER_STATUS_COMPLETE = 'Complete';
  public static ORDER_STATUS_FOLLOWUP = 'Follow Up';
  public static ORDER_STATUS_IN_PRODUCTION = 'In Production';
  public static ORDER_STATUS_ORDER_RECEIVED = 'Order Received';
  public static ORDER_STATUS_IN_TRANSIT = 'In Transit';
  public static ORDER_STATUS_DOMESTIC_STOCK_TRANSFER = 'Domestic Stock Transfer';
  public static ORDER_STATUS_CUSTOMS_HELD = 'Customs Held';
  public static ORDER_STATUS_AVAILABLE_AT_WAREHOUSE = 'Available At Warehouse';
  public static ORDER_STATUS_PACKAGED = 'Packaged';
  public static ORDER_STATUS_PAYMENT_PROCESSING = 'Payment Processing';

  public static QUOTE_STATUS_SHIPPING_REQUIRED = 'Quote Shipping Required';
  public static QUOTE_STATUS_FINAL_PAYMENT = 'Final Payment Pending';
  public static QUOTE_STATUS_PAID_PAYMENT = 'Final Payment Paid';
  public static QUOTE_STATUS_PAYMENT_PENDING = 'Payment Pending';
  public static QUOTE_STATUS_QUOTE = 'Quote';
  public static QUOTE_STATUS_ORDER_RECEIVED = 'Order Received';
  public static QUOTE_STATUS_PACKAGING = 'Arrived At Warehouse';

  public static BADGE_TITLE_FINAL_PAYMENT_OUTSTANDING = 'Final Payment Outstanding';
  public static BADGE_TITLE_BALANCE_REMAINING = 'Balance Remaining';

  public static IS_ARCHIVE_YES = 'Y';
  public static IS_ARCHIVE_NO = 'N';

  public static IS_BAD_DEBT_YES = 'Y';
  public static IS_BAD_DEBT_NO = 'N';

  public static IS_ZERO_DEBT_YES = 'Y';
  public static IS_ZERO_DEBT_NO = 'N';

  public static IS_UL_PUSHED_NO = 'N';

  public static CONFIRM_MESSAGE_CHANGE_STATUS = 'Are you sure you want to change this order’s status to ';

  public static QUOTE_IS_ARCHIVE_YES_CONFIRMATION_MESSAGE = 'Would you like to archive this quote?';
  public static QUOTE_IS_ARCHIVE_NO_CONFIRMATION_MESSAGE = 'Would you like to UnArchive this quote?'
  public static QUOTE_SUCCESS_MESSAGE_IS_ARCHIVE = 'Quote has been archive successfully.';
  public static QUOTE_SUCCESS_MESSAGE_IS_ARCHIVE_NO = 'Quote has been unarchive successfully.';
  public static QUOTE_CONFIRM_MESSAGE_CHANGE_STATUS = 'Are you sure you want to change this quote’s status to ';

  public static ORDER_IS_ARCHIVE_YES_CONFIRMATION_MESSAGE = 'Would you like to archive this order?';
  public static ORDER_IS_ARCHIVE_NO_CONFIRMATION_MESSAGE = 'Would you like to UnArchive this order?'
  public static ORDER_SUCCESS_MESSAGE_IS_ARCHIVE = 'Order has been archive successfully.';
  public static ORDER_SUCCESS_MESSAGE_IS_ARCHIVE_NO = 'Order has been unarchive successfully.';
  public static ORDER_CONFIRM_MESSAGE_CHANGE_STATUS = ' Are you sure you want to change this order’s status to ';
  public static CARRIER_CONFIRM_MESSAGE_CHANGE_STATUS = 'Are you sure you want to change this carrier’s status to ';

  public static ORDER_IS_CANCEL_YES_CONFIRMATION_MESSAGE = 'Would you like to cancel this order?';
  public static ORDER_SUCCESS_MESSAGE_IS_CANCEL = 'Order has been cancel successfully.';

  public static QUOTE_IS_CANCEL_YES_CONFIRMATION_MESSAGE = 'Would you like to cancel this quote?';
  public static QUOTE_SUCCESS_MESSAGE_IS_CANCEL = 'Quote has been cancel successfully.';

  public static SAMPLE_REQUEST_CONFIRM_MESSAGE_CHANGE_STATUS = 'Are you sure you want to change this sample request’s status to ';
  public static SAMPLE_REQUEST_IS_ARCHIVE_YES_CONFIRMATION_MESSAGE = 'Would you like to archive this sample request?';
  public static SAMPLE_REQUEST_IS_ARCHIVE_NO_CONFIRMATION_MESSAGE = 'Would you like to UnArchive this sample request?'
  public static SAMPLE_REQUEST_SUCCESS_MESSAGE_IS_ARCHIVE = 'Sample request has been archive successfully.';
  public static SAMPLE_REQUEST_SUCCESS_MESSAGE_IS_ARCHIVE_NO = 'Sample request has been unarchive successfully.';

  public static STATUS_QUOTE_PAYMENT_DP = 'DP';
  public static STATUS_QUOTE_PAYMENT_FL = 'FL';
  public static STATUS_QUOTE_PAYMENT_FP = 'FP';
  public static STATUS_QUOTE_PARTIAL_PAYMENT = 'PP';


  //text rate constants
  public static PRODUCT_TYPE_HERO_FAMILY_IMAGE = 'HFI';
  public static SUCCESS_MESSAGE_ADD_SHIPPING_PRICE = 'Shipping price has been added successfully.';
  public static ERROR_MESSAGE_PRODUCT_IS_ALREADY_ADDED = 'Product is already added.';

  public static HERO_CATEGORY_SKU_YES = 'YES';
  public static PRODUCT_MAKER_MARK_SALE = 'SALE';
  public static PRODUCT_RESOURCE_DOWNLOAD = 'PR';
  public static PRODUCT_SEAMLESS_TEXTURE_IMAGE_DOWNLOAD = 'STI';
  public static PRODUCT_RECOMMENDED_INSTALLATION_DOWNLOAD = 'PIR';
  public static ACCESSORY_IS_NOT_AVAILABLE_AT_THIS_MOMENT = 'Accessory is not available at this moment.';
  public static ROLE_TRADE_USER = 'Trade';
  public static ROLE_GENERAL_USER = 'General';

  public static QUOTE_CREATE_AS_DRAFT_SUCCESS_MESSAGE = 'Quote has been created as a draft successfully.';
  public static QUOTE_ORDER_CREATE_SUCCESS_MESSAGE = 'Quote has been created successfully.';
  public static QUOTE_ORDER_UPDATED_SUCCESSFULLY_MESSAGE = 'Quote has been updated successfully.';
  public static QUOTE_ITEM_ADDED_SUCCESSFULLY_MESSAGE = 'Product added successfully.';
  public static QUOTE_ORDER_CLONE_SUCCESSFULLY_MESSAGE = 'Quote has been cloned successfully.';
  public static SAMPLE_CREATED_SUCCESS_MESSAGE = 'Sample request has been created successfully.';
  public static SAMPLE_UPDATE_SUCCESS_MESSAGE = 'Sample request has been updated successfully.';
  public static SAMPLE_CLONE_SUCCESS_MESSAGE = 'Sample request has been cloned successfully.';
  public static SAMPLE_SPLIT_SUCCESS_MESSAGE = 'Sample request has been successfully split.';
  public static SHIPPING_IMPORT_SUCCESSFULLY = 'Shipping data has been imported successfully with 15 warnings.';
  public static SHIPPING_IMPORT_ERROR_MESSAGE = 'Shipping import failed'

  public static FILE_UPLOAD_STATUS_COMPLETED = 'C'
  public static FILE_UPLOAD_STATUS_FAILED = 'F'
  public static FILE_SELECTION_REQUIRED ='Please select a file to import data'

  public static COLLECTION_IMPORT_SUCCESSFULLY(warningCount) {
    return `Collection data has been imported successfully with ${warningCount} warnings.`
  };
  public static COLLECTION_IMPORT_ERROR_MESSAGE = 'Collection import failed'

  public static ERROR_MESSAGE_MIN_STOCK_QUANTITY = 'Min order quantity will be ';
  public static PRODUCT_OUT_OF_STOCK = 'Product is Out of stock at this moment.';
  public static SUCCESS_MESSAGE_SCAN_COMPLETED = 'Product has been added successfully for packing.';
  public static SUCCESS_PRODUCT_SCANNED = 'Product has been scanned successfully.';
  public static SUCCESS_PRODUCT_SCANNED_ERROR = 'Product sku is not found.';
  public static ERROR_MESSAGE_SAMPLE_PRODUCT_NOT_EXIST = 'Scanned SKU does not exist.';
  public static PRODUCT_NOT_SCANNED = 'Not all products in this order are included in the packaging. Please add all products to the packaging to change the position.';
  public static CONFIRMATION_BIG_POST_TITLE = 'BigPost Push';
  public static CONFIRMATION_BIG_POST_MESSAGE = 'Are you sure you want to create a job on BigPost platform?';
  public static BIG_POST_JOB_ADDED_SUCCESSFULLY = 'Big post job added successfully.';
  public static BIG_POST_JOB_ALREADY_PUSHED = 'Big post job already created for this sample request';
  public static CONFIRMATION_OFFLINE_PAYMENT_TITLE = 'Offline Payment';
  public static CONFIRMATION_OFFLINE_PAYMENT_MESSAGE = 'Are you sure you want to process offline payment for the order?';
  public static CONFIRMATION_QUOTE_OFFLINE_PAYMENT_MESSAGE = 'Are you sure you want to process offline payment for the quote?';
  public static CONFIRMATION_FINAL_PAYMENT_OVERDUE_TITLE = 'Confirm Status Change';
  public static CONFIRMATION_FINAL_PAYMENT_OVERDUE_MESSAGE = 'Are you sure you want to change this order’s status to Final Payment Outstanding?';

  public static ACTIVITY_TYPE_CREATE_QUOTE = 1;
  public static ACTIVITY_TYPE_QUOTE_STATUS = 2;
  public static ACTIVITY_TYPE_QUOTE_UPDATE = 3;
  public static ACTIVITY_TYPE_DEPOSIT_PAID = 4;
  public static ACTIVITY_TYPE_FINAL_PAYMENT_PAID = 5;
  public static ACTIVITY_TYPE_ORDER_REVIEW = 6;
  public static ACTIVITY_TYPE_PRODUCT_FAVOURITE = 7;
  public static ACTIVITY_TYPE_NEW_CONTACT = 8;
  public static ACTIVITY_TYPE_ACCOUNT_APPROVE = 9;
  public static ACTIVITY_TYPE_ACCOUNT_SUSPEND = 10;
  public static ACTIVITY_TYPE_FULL_PAYMENT_PAID = 11;
  public static ACTIVITY_TYPE_QUOTE_REACTIVATED = 12;
  public static ACTIVITY_TYPE_CONTACT_DECLINED = 13;
  public static ACTIVITY_TYPE_CONTACT_SUSPENDED = 14;
  public static ACTIVITY_TYPE_CONTACT_RESUME = 15;
  public static ACTIVITY_TYPE_ACCOUNT_RESUME = 16;
  public static ACTIVITY_TYPE_PARTIAL_PAYMENT_PAID = 19;

  public static GATHER_COMMITMENT_ADJUST_BALANCE_SUCCESS = 'The client\'s credit balance has been adjusted.';
  public static GATHER_COMMITMENT_ADJUST_BALANCE_ERROR = 'The client\'s credit balance has not been adjusted.';
  public static GATHER_COMMITMENT_ADJUST_BALANCE_ZERO_ERROR = 'Adjust balance should not be zero.';

  public static DEPOSIT_AMOUNT_MORE_THAN_ONE_PERCENTAGE_ERROR = 'The quote deposit amount should not be less than 1%. Please set the deposit amount more than 1%.';
  public static DEPOSIT_AMOUNT_LESS_THAN_NINETYNINE_PERCENTAGE_ERROR = 'The quote deposit amount should not be more than 99%. Please set the deposit amount less than 99%.';

  public static DASHBOARD_PAY_COMMITMENT_DONATE_SUCCESS = 'Commitment payable amount has been donated successfully.';
  public static DASHBOARD_PAY_COMMITMENT_DONATE_ERROR = 'Commitment payable amount has not been donated.';

  public static DONATION_AMOUNT_RESTRICTION_MESSAGE = 'Donation amount must be greater then zero.';

  public static DASHBOARD_COUNT_FILTER_OF_TODAY = 'today';
  public static DASHBOARD_COUNT_FILTER_OF_WEEK = 'week';
  public static DASHBOARD_COUNT_FILTER_OF_MONTH = 'month';
  public static DASHBOARD_COUNT_FILTER_OF_YEAR = 'year';
  public static SAMPLE_ORDER_PACKED_QUANTITY_UPDATED = 'Packed Quantity has been updated successfully.'
  public static SHIPPING_PENDING_APPROVED = 'SPA'
  public static ACTIVITY_NOT_IN_ARRAY = [4, 5, 12, 7];
  public static ACTIVITY_TYPE_NOT_IN_ARRAY = [16, 17, 18];

  public static RESOURCE_ADD_SUCCESSFULLY = 'Resource has been added successfully.';
  public static RESOURCE_EDIT_SUCCESSFULLY = 'Resource has been updated successfully.';
  public static RESOURCE_DELETE_SUCCESSFULLY = 'Resource has been deleted successfully.';

  public static RESOURCE_CONFIRM_DELETE_MESSAGE = 'Are you sure you want to delete this resource?';

  public static CATEGORY_ADD_SUCCESSFULLY = 'Category has been added successfully.';
  public static CATEGORY_EDIT_SUCCESSFULLY = 'Category has been updated successfully.';
  public static CATEGORY_DELETE_SUCCESSFULLY = 'Category has been deleted successfully.';
  public static CATEGORY_CONFIRM_DELETE_MESSAGE = 'Are you sure you want to delete this category?';


  public static SUBCATEGORY_ADD_SUCCESSFULLY = 'Subcategory has been added successfully.';
  public static SUBCATEGORY_EDIT_SUCCESSFULLY = 'Subcategory has been updated successfully.';
  public static SUBCATEGORY_DELETE_SUCCESSFULLY = 'Subcategory has been deleted successfully.';
  public static SUBCATEGORY_CONFIRM_DELETE_MESSAGE = 'Are you sure you want to delete this subcategory?';

  public static PROJECT_ADD_SUCCESSFULLY = 'Project has been added successfully.';
  public static PROJECT_EDIT_SUCCESSFULLY = 'Project has been updated successfully.';
  public static PROJECT_DELETE_SUCCESSFULLY = 'Project has been deleted successfully.';
  public static PROJECT_CONFIRM_DELETE_MESSAGE = 'Are you sure you want to delete this project?';


  public static PROJECT_CATEGORY_CONSTANTS = {
    PROJECT_CATEGORY_ADD_SUCCESSFULLY: 'Project category has been added successfully.',
    PROJECT_CATEGORY_EDIT_SUCCESSFULLY: 'Project category has been updated successfully.',
    PROJECT_CATEGORY_DELETE_SUCCESSFULLY: 'Project category has been deleted successfully.',
    PROJECT_CATEGORY_CONFIRM_DELETE_MESSAGE: 'Are you sure you want to delete this project category?',
  }

  public static ARTICLE_CATEGORY_CONSTANTS = {
    ARTICLE_CATEGORY_ADD_SUCCESSFULLY: 'Article category has been added successfully.',
    ARTICLE_CATEGORY_EDIT_SUCCESSFULLY: 'Article category has been updated successfully.',
    ARTICLE_CATEGORY_DELETE_SUCCESSFULLY: 'Article category has been deleted successfully.',
    ARTICLE_CATEGORY_CONFIRM_DELETE_MESSAGE: 'Are you sure you want to delete this article category?',
  }

  public static BLOG_CRUD_CONSTANTS = {
    CREATE: "Blog category has been added successfully.",
    DELETE_CONFIRM: "Are you sure you want to delete this blog?",
    UPDATE: "Blog category has been updated successfully.",
    DELETE: "Blog category has been deleted successfully.",
  }


  public static BLOG_CATEGORY_CONSTANTS = {
    BLOG_CATEGORY_ADD_SUCCESSFULLY: 'Blog category has been added successfully.',
    BLOG_CATEGORY_EDIT_SUCCESSFULLY: 'Blog category has been updated successfully.',
    BLOG_CATEGORY_DELETE_SUCCESSFULLY: 'Blog category has been deleted successfully.',
    BLOG_CATEGORY_CONFIRM_DELETE_MESSAGE: 'Are you sure you want to delete this blog category?',
  }

  public static PROJECT_CATEGORY_FORM_CATEGORY = {
    MAIN_HEADING: 'Add Project Category',
    LABEL: 'Project Category Name',
    REQUIRED_ERROR_MESSAGE: 'Please enter project category name'
  }


  public static ARTICLE_CATEGORY_FORM_CATEGORY = {
    MAIN_HEADING: 'Add Article Category',
    LABEL: 'Article Category Name',
    REQUIRED_ERROR_MESSAGE: 'Please enter article category name'
  }

  public static EMAIL_TITLE_UPDATE = {
    MAIN_HEADING: 'Update Email Template Title',
    LABEL: 'Email Title',
    REQUIRED_ERROR_MESSAGE: 'Please enter email title'
  }


  public static BLOG_CATEGORY_FORM_CATEGORY = {
    MAIN_HEADING: 'Add Blog Category',
    LABEL: 'Blog Category Name',
    REQUIRED_ERROR_MESSAGE: 'Please enter blog category name'
  }

  public static PROJECT_CATEGORY_EDIT_FORM_CATEGORY = {
    MAIN_HEADING: 'Edit Project Category',
    LABEL: 'Project Category Name',
    REQUIRED_ERROR_MESSAGE: 'Please enter project category name'
  }


  public static ARTICLE_CATEGORY_EDIT_FORM_CATEGORY = {
    MAIN_HEADING: 'Edit Article Category',
    LABEL: 'Article Category Name',
    REQUIRED_ERROR_MESSAGE: 'Please enter article category name'
  }

  public static BLOG_CATEGORY_EDIT_FORM_CATEGORY = {
    MAIN_HEADING: 'Edit Blog Category',
    LABEL: 'Blog Category Name',
    REQUIRED_ERROR_MESSAGE: 'Please enter blog category name'
  }

  public static DRAG_SINGLE_RESTRICTION = 'Please drag single file'
  public static DRAG_SINGLE_IMAGE_VALIDATION = 'Please drag only image file';
  public static IMAGE_SIZE_46_X_46 = '?tr=w-46,h-46';
  public static IMAGE_SIZE_25_X_25 = '?tr=w-25,h-25';
  public static IMAGE_SIZE_590_X_590 = '?tr=w-590,h-590';
  public static IMAGE_SIZE_74_X_74 = '?tr=w-74,h-74';
  public static IMAGE_SIZE_150 = '?tr=w-150';
  public static IMAGE_SIZE_62_X_62 = '?tr=w-62,h-62';
  public static IMAGE_SIZE_50_X_50 = '?tr=w-50,h-50';
  public static IMAGE_SIZE_35_X_35 = '?tr=w-35,h-35';
  public static IMAGE_SIZE_165_X_165 = '?tr=w-165,h-165';
  public static IMAGE_SIZE_300 = '?tr=w-300';


  public static IMAGE_SIZES = {
    IMAGE_100_X_300: '?tr=w-300,h-100',
    IMAGE_453_X_453: '?tr=w-453,h-453',
    IMAGE_591_X_964: '?tr=w-964,h-591',
    IMAGE_WIDTH_964: '?tr=w-964',
    IMAGE_WIDTH_453: '?tr=w-453',
  }

  public static BLOGS_CONSTANTS = {
    CREATE: 'Blog has been added successfully.',
    UPDATE: 'Blog has been updated successfully.',
    DELETE: 'Blog has been deleted successfully.',
    DELETE_CONFIRM: 'Are you sure you want to delete this blog?'
  }

  public static ARTICLE_CONSTANTS = {
    CREATE: 'Article has been added successfully.',
    UPDATE: 'Article has been updated successfully.',
    DELETE: 'Article has been deleted successfully.',
    DELETE_CONFIRM: 'Are you sure you want to delete this article?'
  }

  public static PROJECT_IMAGE_CONSTANTS = {
    IMAGE_DELETE: "Project image has been deleted successfully.",
    IMAGE_UPDATE: "Project image has been updated successfully.",
  }

  public static DELETE_PRODUCT_FROM_QUOTE_CONSTANTS = {
    TITLE: 'Delete Product',
    BODY_MESSAGE: 'Are you sure you want delete this product from this quote?',
    NO: "No",
    YES: "Yes"
  }

  public static DELETE_PRODUCT_FROM_SAMPLE_REQUEST_CONSTANTS = {
    TITLE: 'Delete Product',
    BODY_MESSAGE: 'Are you sure you want delete this product from this sample request?',
    NO: "No",
    YES: "Yes"
  }

  public static DELETE_QUOTE_ORDER_CONSTANTS = {
    TITLE: 'Delete Quote/Order',
    BODY_MESSAGE: 'Are you sure you want delete this quote/order?',
    NO: "No",
    YES: "Yes"
  }

  public static DELETE_QUOTE_CONSTANTS = {
    TITLE: 'Delete Quote',
    BODY_MESSAGE: 'Are you sure you want delete this quote?',
    NO: "No",
    YES: "Yes"
  }

  public static UNLINK_QUOTE_CONSTANTS = {
    TITLE: 'Unlink Quote',
    BODY_MESSAGE: 'Are you sure you want to unlink this quote?',
    NO: "No",
    YES: "Yes"
  }

  public static UNLINK_ORDER_CONSTANTS = {
    TITLE: 'Unlink Order',
    BODY_MESSAGE: 'Are you sure you want to unlink this order?',
    NO: "No",
    YES: "Yes"
  }

  public static CLONE_QUOTE_CONSTANTS = {
    TITLE: 'Clone Quote',
    BODY_MESSAGE: 'Are you sure you want clone this quote?',
    NO: "No",
    YES: "Yes"
  }

  public static CLONE_DRAFT_QUOTE_CONSTANTS = {
    TITLE: 'Clone Draft',
    BODY_MESSAGE: 'Are you sure you want clone this draft?',
    NO: "No",
    YES: "Yes"
  }

  public static CLONE_SAMPLE_CONSTANTS = {
    TITLE: 'Clone Sample Request',
    BODY_MESSAGE: 'Are you sure you want clone this sample request?',
    ZERO_QUANTITY_PRODUCT_NOTE: 'Products with a packed quantity of zero (0) will be removed from this sample request.',
    NO: "No",
    YES: "Yes"
  }

  public static SPLIT_DELIVERY_SAMPLE_CONSTANTS = {
    TITLE: 'Split Delivery Sample Request',
    BODY_MESSAGE: 'Are you sure you want to split this sample request?',
    SPLIT_CONDITION_NOTE: 'Products with a remaining "Packed Qty" will be pulled across to the new sample request.',
    NO: "No",
    YES: "Yes"
  }

  public static DELETE_ORDER_CONSTANTS = {
    TITLE: 'Delete Order',
    BODY_MESSAGE: 'Are you sure you want delete this order?',
    NO: "No",
    YES: "Yes"
  }

  public static CLONE_ORDER_CONSTANTS = {
    TITLE: 'Clone Order',
    BODY_MESSAGE: 'Are you sure you want clone this order?',
    NO: "No",
    YES: "Yes"
  }

  public static DELETE_SAMPLE_REQUEST_CONSTANTS = {
    TITLE: 'Delete Sample Request',
    BODY_MESSAGE: 'Are you sure you want delete this sample request?',
    NO: "No",
    YES: "Yes"
  }

  public static QUOTE_ORDER_MESSAGE_CONSTANTS = {
    PRODUCT_REMOVE: "Product remove successfully",
    PRODUCT_RESTRICTION_FOR_LAST_PRODUCT: "You cannot delete the last product from the sample request.",
    QUOTE_PRODUCT_RESTRICTION_FOR_LAST_PRODUCT: "You cannot delete the last product from the quote.",
    QUOTE_ORDER_REMOVE: "Quote/Order has been deleted successfully.",
    QUOTE_REMOVE: "Quote has been deleted successfully.",
    UNLINK_QUOTE: "Quote has been unlinked successfully.",
    ORDER_REMOVE: "Order has been deleted successfully.",
    UNLINK_ORDER: "Order has been unlinked successfully.",
    UNAUTHORIZED_UNLINK_QUOTE: 'You are not authorized to unlink this quote.',
    UNAUTHORIZED_UNLINK_ORDER: 'You are not authorized to unlink this order.'
  }

  public static SAMPLE_REQUEST_MESSAGE_CONSTANTS = {
    SAMPLE_REQUEST_REMOVE: "The sample request has been deleted successfully.",
    PRODUCT_REMOVE: "The product has been removed from the sample request successfully.",
    PRODUCT_RESTRICTION_FOR_LAST_PRODUCT: "You cannot delete the last product from the sample request.",
  }

  public static PDF_UPLOAD_ALERT = 'Please upload pdf files only'
  public static FAQ_CONSTANTS = {
    UPDATE: "FAQ has been updated successfully.",
    ARTICLE_ASSIGN_SUCCESSFULLY: "Article has been assigned successfully.",
    SELECT_ARTICLE_MESSAGE: "Please select articles"
  }

  public static BIG_POST = {
    CREATE: 'Big post job has been added successfully.',
    CARTON_TYPE: 'Carton'
  }
  public static USER_UPDATE_PROFILE_CONSTANTS = {
    IMAGE_TYPE_VALIDATION_MESSAGE: 'Please upload image',
    IMAGE_SIZE_VALIDATION: 'Maximum file size to upload is 25MB . If you are uploading a photo, try to reduce its resolution to make it under 25MB'
  }

  public static FILE_UPLOAD_CONSTANTS = {
    IMAGE_TYPE_VALIDATION_MESSAGE: 'Please upload image',
    IMAGE_SIZE_VALIDATION: 'Maximum file size to upload is 40MB .'
  }

  public static SUCCESS_RESET_PASSWORD = 'Your password has been updated successfully.';
  public static SUCCESS_USER_PROFILE_UPDATE = 'Your profile has been updated successfully.';

  public static CART_PRODUCT_EXIST_MESSAGE = 'This product is already exist in your quote.';
  public static SAMPLE_CART_PRODUCT_EXIST_MESSAGE = 'This product is already exist in your sample request.';


  public static NOMINATE_DATE_MAIL = {
    TITLE: 'Confirm Nominate Date Mail Send',
    MESSAGE: 'Are you sure you want to send Nominate Delivery Date email to a User?',
    MAIL_SEND: 'Mail has been send to user successfully.',
    MAIL_NOT_SEND: 'Mail has been not send to user.',
  }

  public static DELIVERY_DATE_SET = `Delivery date has been successfully set to this Order.`

  // public static EMAIL_FOOTER_CONTENT = `This email is intended only to be read or used by the addressee. It is confidential and may contain legally privileged information. If you are not the addressee indicated in this message (or responsible for delivery of the message to such person), you may not copy or deliver this message to anyone, and you should destroy this message and kindly notify the sender by reply email. Confidentiality and legal privileges are not waived or lost by reason of mistaken delivery to you. Please consider the environment before printing this email.`;
  public static EMAIL_FOOTER_CONTENT = `This email is intended only to be read or used by the addressee. It is confidential and may contain legally privileged information. If you are not the addressee indicated in this message (or responsible for delivery of the message to such person), you may not copy or deliver this message to anyone, and you should destroy this message and kindly notify the sender by reply email. Confidentiality and legal privileges are not waived or lost by reason of mistaken delivery to you. Please consider the environment before printing this email.`;

  public static EMAIL_TEMPLATE_TITLE = {
    ADMIN: 'Preview of Admin/Sales Rep. Email',
    CUSTOMER: 'Preview of Customer Email',
    CARRIER: 'Preview of Carrier Email'
  }

  public static EMAIL_TEMPLATE_TITLE_MESSAGE = {
    SUCCESS_EMAIL_TEMPLATE_TITLE: 'Email template title has been updated successfully.',
    SUCCESS_EMAIL_TEMPLATE_BODY: 'Email template body has been updated successfully.',
    SUCCESS_EMAIL_TEMPLATE_INSTRUCTION: 'Email template instruction has been updated successfully.'
  }
  public static EMAIL_TEMPLATE_KEY_NAME = [
    {ID: 0, EMAIL_FOR: 'NOMINATE_DELIVERY_DATE', KEY_NAME: 'nominate-delivery-date', status: ['ONDD'], TYPE: 'O'},
    {ID: 1, EMAIL_FOR: 'SHIPPING_QUOTE_REQUIRED', KEY_NAME: 'shipping-quote-added', status: ['MDQR'], TYPE: 'O'},
    {ID: 2, EMAIL_FOR: 'GOODS_DISPATCH', KEY_NAME: "order-dispatched", status: ['GD'], TYPE: 'O'},
    {ID: 3, EMAIL_FOR: 'ARRIVAL_AT_WAREHOUSE', KEY_NAME: 'stock-landed', status: ['AAW'], TYPE: 'O'},
    {ID: 4, EMAIL_FOR: 'FINAL_PAYMENT_PENDING', KEY_NAME: 'final-payment-reminder', status: ['FPP'], TYPE: 'O'},
    {ID: 5, EMAIL_FOR: 'IN_TRANSIT', KEY_NAME: 'in-transit', status: ['IT'], TYPE: 'O'},
    {ID: 6, EMAIL_FOR: 'ORDER_COMPLETED', KEY_NAME: 'order-complete', status: ['C'], TYPE: 'O'},
    {ID: 7, EMAIL_FOR: 'SHIPPING_REQUIRED', KEY_NAME: 'shipping-quote-required', status: ['SR'], TYPE: 'O'},
    {ID: 8, EMAIL_FOR: 'SAMPLE_ORDER_COMPLETED', KEY_NAME: 'sample-order-complete', status: ['D'], TYPE: 'SO'},
    {ID: 9, EMAIL_FOR: 'SAMPLE_DISPATCH', KEY_NAME: 'sample-dispatched', status: ['OIW'], TYPE: 'SO'},
    {
      ID: 10,
      EMAIL_FOR: 'GOODS_DISPATCH_PENDING',
      KEY_NAME: 'trade-user-carrier-added',
      status: ['GDOTT'],
      TYPE: 'O'
    },
  ]

  public static EMAIL_TEMPLATE_KEY_NAME_FOR_ADD_RECIPIENT = [
    'nominate-delivery-date',
    'shipping-quote-added',
    'order-dispatched',
    'stock-landed',
    'final-payment-reminder',
    'in-transit',
    'order-complete',
    'shipping-quote-required',
    'sample-order-complete',
    'sample-dispatched',
    'trade-user-carrier-added',
    'trade-user-sample-create',
    'trade-user-quote-create',
    'sample-request-received',
    'trade-existing-user-quote-create'
  ];

  public static ACCOUNT_STATUS_EMAIL_KEY_NAME = {
    SUSPENDED_ACCOUNT: 'trade-user-account-suspended',
    DECLINED_ACCOUNT: 'trade-user-account-denied'
  }

  public static EMAIL_FOR_NEW_CONTACT = {
    SAMPLE: 'trade-user-sample-create',
    QUOTE: 'trade-user-quote-create'
  }

  public static EMAIL_FOR_SHIPPING_QUOTE_REQUIRED = 'shipping-quote-required'
  public static EMAIL_FOR_SHIPPING_QUOTE_PENDING = 'shipping-quote-pending'

  public static EMAIL_KEYS = {
    SAMPLE_REQUEST_RECEIVED: 'sample-request-received',
    NOMINATED_DELIVERY_DATE: 'nominate-delivery-date',
    QUOTE_CREATE_FOR_EXISTING_USER: 'trade-existing-user-quote-create',
    TRADE_ACCOUNT_APPROVED: 'trade-user-account-approved'
  }

  public static CREATE_QUOTE_WITH_NEW_ACCOUNT = 'Please submit your contact form after that you can submit Quote.';
  public static CREATE_SAMPLE_WITH_NEW_ACCOUNT = 'Please submit your contact form after that you can submit Sample Request.';
  public static STREET_NO_REQUIRED = 'Please enter valid address street number is missing.'
  public static TRACKING_NUMBER_COPIED = 'Tracking number copied!'
  public static ADDRESS_COPIED = 'Collection Address copied!'
  public static CRN_COPIED = 'BPAY Client Reference copied!'

  public static CONTACT_INFO = 'contactInfo'
  public static DELIVERY_DETAIL_INFO = 'deliveryDetailInfo'
  public static TRUCK_TYPE_INFO = 'truckTypeInfo'
  public static STOCK_REQUIRED_DATE_INFO = 'stockRequiredDateInfo'

  public static QUOTE_ORDER_CONTACT_UPDATE = 'Contact has been updated successfully.'
  public static SITE_CONTACT_DELIVERY_DETAIL_UPDATE = 'Site contact delivery detail has been updated successfully.'
  public static QUOTE_ORDER_DELIVERY_DETAIL_UPDATE = 'Delivery address has been updated successfully.'
  public static CRATES_UPDATE = 'Crates has been updated successfully.'
  public static TRUCK_TYPE_DETAIL_UPDATE = 'Truck type detail has been updated successfully.'
  public static STOCK_REQUIRED_DATE_UPDATE = 'Stock required date has been updated successfully.'
  public static DELIVERY_INSTRUCTION_UPDATE = 'Delivery instruction has been updated successfully.'
  public static DELIVERY_INSTRUCTION_ADDED = 'Delivery instruction has been added successfully.'
  public static QUOTE_ORDER_PROJECT_DETAIL_UPDATE = 'Project detail has been updated successfully.'

  public static PO_BOX_ADDRESS_REGEX = /\b(?:p\.?\s*o\.?|post\s+office)(\s+)?(?:box|[0-9]*)?\b/i

  public static IN_SHIPPING_ZONE_YES = 'Y';
  public static IN_SHIPPING_ZONE_NO = 'N';

  public static IS_EXPIRED_YES = 'YES';
  public static IS_EXPIRED_NO = 'NO';
  public static S3_BUCKET_URL = 'https://ik.imagekit.io/gatherco/WEBSITE_ASSET_STAGING';
  public static NO_IMAGE_PRODUCT = 'https://ik.imagekit.io/gatherco/WEBSITE_ASSET_STAGING/no-image-product.svg';
  static S3_PRODUCT_IMAGES_IMAGE_KIT_URL = environment.imageKitEndpoint;
  public static S3_PRODUCT_RESOURCE_IMAGE_KIT_URL = environment.imageKitEndpoint + ''
  public static S3_ACCOUNT_LOGO_IMAGE_KIT_URL = environment.imageKitEndpoint + '/account-logo'
  public static S3_FINISH_SPECIFICATION_IMAGE_KIT_FOLDER = '/ICONS/Specifications'
  public static S3_MAKER_MARK_IMAGE_KIT_URL = environment.imageKitEndpoint + '/ICONS/MakersMarks'
  public static S3_USER_LOGO_IMAGE_KIT_URL = environment.imageKitEndpoint + '/profile-picture/'
  public static S3_PRODUCT_IMPORT_KIT_URL = environment.imageKitEndpoint + '/product-import/'


  public static IS_ORDER = 'Y';

  public static ACCEPT_QUOTE_CONSTANTS = {
    TITLE: 'Accept Quote',
    BODY_MESSAGE: 'Are you sure you want to accept this quote on behalf of your client?',
    NO: "Cancel",
    YES: "Accept Quote"
  }

  public static REACTIVE_QUOTE_CONSTANTS = {
    TITLE: 'Reactivate Quote',
    BODY_MESSAGE: 'Are you sure you want to reactivate this quote?',
    NO: "Cancel",
    YES: "Reactivate Quote"
  }

  public static SUCCESS_QUOTE_ACCEPTED = 'Quote has been accepted successfully.';
  public static SUCCESS_QUOTE_NOT_ACCEPTED = 'Quote has been not accepted.';
  public static SUCCESS_QUOTE_REACTIVATED = 'Quote has been reactivate successfully.';

  public static QUOTE_QUANTITY_UPDATED = 'Quantity has been updated successfully.';
  public static QUOTE_DISCOUNT_PRICE_UPDATED = 'Discount Price has been updated successfully.';
  public static INTERNAL_NOTE_ADDED = 'Internal note has been added successfully.';
  public static INTERNAL_NOTE_UPDATE = 'Internal note has been updated successfully.';
  public static EXTERNAL_NOTE_ADDED = 'External note has been added successfully.';
  public static EXTERNAL_NOTE_UPDATE = 'External note has been updated successfully.';
  public static MILESTONE_NOTE_ADDED = 'Milestone note has been added successfully.';
  public static MILESTONE_NOTE_UPDATE = 'Milestone note has been updated successfully.';

  public static PACKING_SLIP_UPLOAD = 'Packing slip has been uploaded successfully.';
  public static PACKING_SLIP_REQUIRED = 'Please select file.';
  public static SUCCESS_MESSAGE_ON_PACKING_SLIP_DELETE = 'Packing slip has been deleted successfully.';

  public static PARTIAL_PAYMENT_RECEIPT_UPLOAD = 'Payment remittance has been uploaded successfully.';
  public static PARTIAL_PAYMENT_RECEIPT_REQUIRED = 'Please select file.';
  public static SUCCESS_MESSAGE_ON_PARTIAL_PAYMENT_RECEIPT_DELETE = 'Payment receipt has been deleted successfully.';
  public static AMOUNT_NOT_NULL_MESSAGE = 'Please input an amount that is greater than or equal to zero to continue.';
  /**
   * Identify download type
   */
  public static DOWNLOAD_QUOTE = 'DQ';
  public static DOWNLOAD_INVOICE = 'DI';
  public static DOWNLOAD_PAYMENT_RECEIPT = 'DPR';
  public static DOWNLOAD_CONSUMABLES = 'DCS';

  public static GATHERCO = {
    ABN_NUMBER: '65 601 533 144',
    ADDRESS: 'Sydney - AUSTRALIA',
    CONTACT_NUMBER: '1800 428 437',
    EMAIL_ADDRESS: 'hi@gatherco.com.au',
    INFO: '@gathercoaustralia',
  }

  public static WAREHOUSE_COLLECTION_ADDRESS = {
    ADDRESS_LINE_1: '89 Fairey Road',
    ADDRESS_LINE_2: '',
    SUBURB: 'South Windsor',
    TOWN_CITY: 'Hawkesbury City Council',
    STATE_REGION: 'NSW',
    POST_CODE: '2756',
    COUNTRY: 'Australia',
  }

  public static EMAIL_IS_REQUIRED_FOR_PDF_EXPORT = `Please select or enter email address`;

  /**
   * Identify share type
   */
  public static SHARE_QUOTE = 'SQ';
  public static SHARE_INVOICE = 'SI';
  public static SHARE_PAYMENT_RECEIPT = 'SPR';

  public static SUCCESS_MESSAGE_ON_SHARE_QUOTE = 'Please check your email address for the shared quote PDF.';
  public static SUCCESS_MESSAGE_ON_SHARE_ORDER = 'Please check your email address for the shared invoice PDF.';
  public static SUCCESS_MESSAGE_ON_SHARE_PAYMENT = 'Please check your email address for the shared receipt PDF.';
  public static INFO_MESSAGE_ON_FINAL_PAYMENT = "You can't make the final payment until deposit paid.";

  public static GOOGLE_MAP_URL = 'https://www.google.com/maps/place/';

  public static UNSPECIFIED_CARRIER_STATUS = 'Unspecified';
  public static CARRIER_CREATED = 'Carrier has been created successfully.';
  public static CARRIER_UPDATED = 'Carrier has been updated successfully.';
  public static CARRIER_QUOTE_UPDATED = 'Carrier has been set successfully.';

  public static SUCCESS_MESSAGE_ON_EXPORT_PDF_QUOTE = 'Export quote link has been sent to the entered email address.';
  public static SUCCESS_MESSAGE_ON_EXPORT_PDF_INVOICE = 'Export invoice link has been sent to the entered email address.';

  public static SUCCESS_MESSAGE_ON_EXPORT_PDF_TRACKING_LINK = 'Export order tracking link has been sent to the entered email address.';

  public static SUCCESS_MESSAGE_ON_EXPORT_PDF_CONSUMABLE_SUMMARY = 'Consumable summary PDF has been attached and sent to the entered email address.';

  public static SUCCESS_MESSAGE_ON_STATUS_MAIL_SENT = 'Status update mail has been sent successfully, Please check your email.';
  public static ERROR_MESSAGE_ON_STATUS_MAIL_SENT = 'An error occurred while sending status update mail.';

  public static SUCCESS_MESSAGE_ON_MDQR_MAIL_SENT = 'Shipping quote required mail has been sent successfully.';
  public static ERROR_MESSAGE_ON_MDQR_MAIL_SENT = 'An error occurred while sending shipping quote required mail.';

  public static SUCCESS_MESSAGE_ON_NOMINATE_DELIVERY_DATE_MAIL_SENT = 'Nominate delivery date mail has been sent successfully, Please check your email.';
  public static ERROR_MESSAGE_ON_NOMINATE_DELIVERY_DATE_MAIL_SENT = 'An error occurred while sending nominate delivery date mail.';

  public static TAX_GST_PERCENTAGE = 10;
  public static PRODUCT_PRICE_ZERO_MESSAGE = 'Product price must be grater than 0';

  public static NOTES = {
    NOTE_TYPE_INTERNAL: 'internal',
    NOTE_TYPE_EXTERNAL: 'external',
    NOTE_TYPE_MILESTONE: 'milestone'
  }

  public static PRODUCT_ADDED_SUCCESS_MESSAGE(moduleName: String) {
    return `Product has been added successfully to the ${moduleName}.`
  };

  public static IS_FINAL_PAYMENT_PAID(paymentBadgeTitle?: string) {
    if (paymentBadgeTitle && paymentBadgeTitle.includes('<br>')) {
      paymentBadgeTitle = paymentBadgeTitle.replace(/<br>/g, '');
    }
    if (paymentBadgeTitle == Constants.BADGE_TITLE_FINAL_PAYMENT_OUTSTANDING) {
      return `Final Payment Outstanding:`;
    } else {
      return `Remaining Balance:`;
    }
  }

  public static SUCCESS_MESSAGE_ON_SAMPLE_REQUEST_MAIL_SEND = 'Sample request email has been sent to the entered email address.';

  public static DELIVERY_ADDRESS_REQUIRED = 'Delivery Address Required';
  public static MANUAL_DELIVERY_QUOTE_REQUIRED = 'Manual Delivery Quote Required';
  public static MANUAL_DELIVERY_QUOTE_ADDED = 'Manual Delivery Quote Added';
  public static MANUAL_DELIVERY_QUOTE_APPLIED = 'Manual Delivery Quote Applied';
  public static AUTO_DELIVERY_QUOTE_APPLIED = 'Auto Delivery Quote Applied';
  public static GOODS_DISPATCHED_UNSPECIFIED = 'Goods Despatch Unspecified';
  public static GOODS_DISPATCHED_PENDING = 'Goods Despatch Out To Tender';
  // public static GOODS_DISPATCHED_CONFIRMED = 'Goods Despatch Despatched';
  public static GOODS_DISPATCHED_CONFIRMED = 'Goods Despatched';
  public static GOODS_DISPATCHED_DELIVERD = 'Goods Delivered';
  public static GOODS_DISPATCHED_ACCEPTED = 'Goods Despatch Accepted';
  public static GOODS_DISPATCHED_DECLINED = 'Goods Despatch Declined';
  public static GOODS_DISPATCHED_CLIENT_ORGANIZED = 'Goods Despatch Client Organized';
  public static QUOTE_EXPIRED_STATUS_NAME = 'Expired';
  public static QUOTE_FOLLOW_UP_STATUS_NAME = 'Follow Up';
  public static QUOTE_ACCEPTED_STATUS_NAME = 'Quote Accepted';
  public static ORDER_FINAL_PAYMENT_OUTSTANDING_STATUS_NAME = 'Final Payment Outstanding';
  public static ORDER_FINAL_PAYMENT_PAID_STATUS_NAME = 'Final Payment Paid';
  public static ORDER_COMPLETE_STATUS_NAME = 'Complete';
  public static QUOTE_STATUS_ARCHIVED = 'Archived';
  public static QUOTE_STATUS_BAD_DEBT = 'Bad Debt';
  public static CLIENT_ORGANIZED_ORDER_COLLECTION = 'Client Organized Order Collection';
  public static ORDER_WAREHOUSE_COLLECTION_CARRIER_NAME = 'Client Organized';

  // public static CREDIT_APPLY_ERROR(profileName: any) {
  //     return `Credit applied may only amount to a maximum ${profileName}% of total order value.`
  // };

  public static VIEW_QUOTE_CONSTANTS = {
    //#region   SINGLE CONSTANTS
    NEW_CREDIT_EARN: `This is calculated on the sub-total minus the credit applied to this order.`,
    CREDIT_APPLY_ERROR: `Credit applied may only amount to a maximum 49% of total order value.`,
    CREDIT_APPLY_SLIDER_DESCRIPTION: `Shift the slider below to assign part or all of your available credit to this quote. The credit applied will <u><b>not</b></u> be deducted from the initial deposit sum, but rather - will be subtracted from your final payment.`,

    ORDER_DETAIL_AND_DELIVERY: `This platform operates our automated trucking calculator. For many destinations, completing the below form instantly generates an applicable delivery fee.`,
    //#endregion

    // EXPIRE QUOTE
    EXPIRE_QUOTE: {
      TOP_DESCRIPTION_FOR_REACTIVE: ` You can reactivate this quote, however, the availability of certain
    stock and sizes as well as prices may have changed.`
    },

    //TERM & CONDITIONS
    TERMS_CONDITIONS: {
      FOOTER: `Acceptance:<br>You willing accept our full set of Terms when:<br>
      (a) you provide us with a completed account application form or apply for an online account;<br>
      (b) you submit an Order (online or otherwise);<br>
      (c) you accept Delivery of, or any part of ,any Goods pursuant to an Order; or<br>
      (d) you make Payment ,or partial Payment, for any Goods supplied by us,<br>
      whichever occurs first.`
    },

    //#region TOP DESCRIPTION
    TOP_DESCRIPTION: {
      DEPOSIT_PAYMENT__IS_REQUIRED: `Delivery on this order must be calculated manually, and will be billed on
      a<br /> separate invoice. We will be in contact shortly.`,
      QUOTE_CREATED_SHORT_IN_SHIPPING_ZONE_NO: `Due to address delivery a <b>Request delivery quote</b>
      must be made. The delivery cost will then be added to your order prior to deposit payment. Response will be within 24 - 48 hrs.`,
      QUOTE_MANUAL_DELIVERY_QUOTE_ADDED_SHORT: `Delivery cost has been calculated and added below. Please approved<br />
      delivery cost to create
      final order.`
    },

    //#endregion

    //#region FOOTER DESCRIPTION
    FOOTER_DESCRIPTION: {
      OUT_OF_DELIVERY_ZONE: ` Transport must be calculated manually, and will be billed on a separate
      invoice. We will be in
      contact shortly.`,
      IN_SHIPPING_ZONE: `Delivery is
      calculated
      automatically,
      based on pre-determined
      parameters. Please <a href="/contact-us">make contact</a> for further information.`,
      QUOTE_CREATED_SHORT_IN_SHIPPING_ZONE: ` Delivery must be calculated manually, and will be added to your order
      prior to deposit payment.
      Once you request a delivery quote, it will become an Order. Response
      will be within 24 - 48 hrs.`
    }
    //#endregion
  }

  public static DELETE_NOTE_CONSTANTS = {
    TITLE: 'Delete Note',
    BODY_MESSAGE: 'Are you sure you want delete this note?',
    NO: "No",
    YES: "Yes"
  }

  public static DELETE_INTERNAL_NOTE_CONSTANTS = {
    TITLE: 'Delete Internal Note',
    BODY_MESSAGE: 'Are you sure you want delete this internal note?',
    NO: "No",
    YES: "Yes"
  }

  public static DELETE_ATTACH_FILE_CONSTANTS = {
    TITLE: 'Delete Attached File',
    BODY_MESSAGE: 'Are you sure you want to delete this attached file?',
    NO: "No",
    YES: "Yes"
  }

  public static DELETE_PACKING_SLIP = {
    TITLE: 'Delete Packing Slip',
    BODY_MESSAGE: 'Are you sure you want to delete this Packing Slip?',
    NO: "No",
    YES: "Yes"
  }

  public static DELETE_PARTIAL_PAYMENT_RECEIPT = {
    TITLE: 'Delete Payment Receipt',
    BODY_MESSAGE: 'Are you sure you want to delete this Payment Remittance?',
    NO: "No",
    YES: "Yes"
  }

  public static SUCCESS_MESSAGE_ON_INTERNAL_NOTE_DELETE = 'Internal note has been deleted successfully.';
  public static SUCCESS_MESSAGE_ON_MILESTONE_NOTE_DELETE = 'Milestone note has been deleted successfully.';
  public static SUCCESS_MESSAGE_ON_ATTACH_FILE_DELETE = 'Attached  File has been deleted successfully.';

  public static ERROR_MESSAGE_ON_INTERNAL_NOTE_DELETE = 'An error occurred while deleting internal note.';

  public static TRUCK_TYPE_FORK_TRUCK_ID = 1;
  public static TRUCK_TYPE_HI_AB_TRUCK_ID = 2;
  public static TRUCK_TYPE_FLATBED_TRUCK_ID = 3;
  public static TRUCK_TYPE_OTHER_ID = 4;

  public static UPDATE_PRODUCT_QUANTITY_AFTER_DELIVERY_CHARGE_ADDED = {
    //BODY_MESSAGE: 'Applied delivery charge will be re-calculated. Are you sure you want update the quantity of this product?',
    BODY_MESSAGE: 'This quote has already had a manual delivery fee calculated. Proceeding with further edits will require a new delivery fee to be manually applied. Do you still want to continue?',
  }

  public static UPDATE_PRODUCT_DISCOUNT_AFTER_DELIVERY_CHARGE_ADDED = {
    //BODY_MESSAGE: 'Applied delivery charge will be re-calculated. Are you sure you want update the quantity of this product?',
    BODY_MESSAGE: 'This quote has already had a manual delivery fee calculated. Proceeding with further edits will require a new delivery fee to be manually applied. Do you still want to continue?',
  }

  public static UPDATE_QUOTES_AFTER_DEPOSIT_PAYMENT_DUE_CREDIT_APPLIED = {
    BODY_MESSAGE: 'The current status of this quote is "DEPOSIT PAYMENT DUE." Once you add the product or update the quantity, the quote will become editable, and consequently, its status will change. Please confirm this information.',
  }

  public static DEPOSIT_PAYMENT_PAID_SUCCESS = 'Deposit payment was successfully processed.';
  public static FINAL_PAYMENT_PAID_SUCCESS = 'Final payment was successfully processed.';
  public static FULL_PAYMENT_PAID_SUCCESS = 'Full payment was successfully processed.';
  public static PARTIAL_PAYMENT_PAID_SUCCESS = 'Partial payment was successfully processed.';

  public static NOTE_TYPE_ACCOUNT = 'AN';
  public static NOTE_TYPE_CONTACT = 'CN';
  public static NOTE_TYPE_QUOTE = 'QN';
  public static PRODUCT_TYPE_SM = '';
  public static SAMPLE_PRODUCT_TYPE_SM = 'SM';
  public static IS_DEFAULT_ADDRESS_YES = 'Y';
  public static IS_DEFAULT_ADDRESS_NO = 'N';
  public static IS_DISPLAY_FOR_SAMPLE_LIBRARY_YES = 'Y';
  public static IS_DISPLAY_FOR_SAMPLE_LIBRARY_NO = 'N';
  public static IS_NEW_USER_YES = '1';
  public static IS_NEW_USER_NO = '0';

  public static PRODUCT_IS_PREVIOUSLY_ORDERED_IN_SAMPLE_REQUEST = 'This account has previously requested these samples.<br> Would you still like to proceed?';
  public static ERROR_FOR_DOWNLOAD_TABLE = 'Please select the user to download the table.';

  public static CONSUMABLE_QUANTITY_TYPE_GOLD = 'GOLD';
  public static CONSUMABLE_QUANTITY_TYPE_DEFENDER = 'DEFENDER';
  public static CONSUMABLE_QUANTITY_TYPE_LATICRETE_ADHESIVE = 'LATICRETE_ADHESIVE';

  public static SUCCESS_MESSAGE_OF_FINISH_VALUE_UPDATE = 'Finish value has been updated successfully.';
  public static SUCCESS_MESSAGE_OF_FINISH_VALUE_ADDED = 'Finish value has been added successfully.';
  public static SUCCESS_MESSAGE_OF_FINISH_VALUE_REMOVED = 'Finish value has been removed successfully.';

  public static SUCCESS_MESSAGE_OF_PRICE_VALUE_UPDATE = 'Price value has been updated successfully.';
  public static SUCCESS_MESSAGE_OF_PRICE_VALUE_ADDED = 'Price value has been added successfully.';
  public static SUCCESS_MESSAGE_OF_PRICE_VALUE_REMOVED = 'Price value has been removed successfully.';

  public static SUCCESS_MESSAGE_OF_ADHESIVE_COVERAGE_VALUE_UPDATE = 'Adhesive coverage value has been updated successfully.';
  public static SUCCESS_MESSAGE_OF_ADHESIVE_COVERAGE_VALUE_ADDED = 'Adhesive coverage value has been added successfully.';
  public static SUCCESS_MESSAGE_OF_ADHESIVE_COVERAGE_VALUE_REMOVED = 'Adhesive coverage value has been removed successfully.';

  public static CONSUMABLE_CALCULATOR_TYPE_ADHESIVE = 'laticrete_adhesive';
  public static CONSUMABLE_CALCULATOR_TYPE_DEFENDER = 'defender';
  public static CONSUMABLE_CALCULATOR_TYPE_GOLD = 'gold';

  public static CONSUMABLE_CALCULATOR_CONSTANTS = {
    TYPE_ADHESIVE: `Laticrete 335 Adhesive`,
    TYPE_DEFENDER: `Actichem CSD Defender`,
    TYPE_GOLD: `Actichem Solv Sealer Gold`,
    // ADDED_TO_QUOTE_SUCCESS_MESSAGE: `Consumable calculator has been added to the quote successfully.`,
    // ADDED_TO_QUOTE_ERROR_MESSAGE: `Consumable calculator has not been added to the quote.`,
    // REMOVED_FROM_QUOTE_SUCCESS_MESSAGE: `Consumable calculator has been removed from the quote successfully.`,
    // REMOVED_FROM_QUOTE_ERROR_MESSAGE: `Consumable calculator has not been removed from the quote.`,
    ADDED_TO_QUOTE_SUCCESS_MESSAGE: `Selected item successfully added to your quote.`,
    ADDED_TO_QUOTE_ERROR_MESSAGE: `Selected item successfully not added to your quote`,
    REMOVED_FROM_QUOTE_SUCCESS_MESSAGE: `Selected item successfully removed from your quote.`,
    REMOVED_FROM_QUOTE_ERROR_MESSAGE: `Selected item successfully not removed from your quote.`,
  }

  public static EMAIL_TEMPLATE_TRADE_USER_CARRIER_ADDED_ID = 42;
  public static EMAIL_TEMPLATE_TRADE_USER_CARRIER_ADDED_KEY_NAME = 'trade-user-carrier-added';
  public static JOB_ACCEPTED = 'A';
  public static JOB_DECLINED = 'D';
  public static IS_USED_OWN_TRANSPORT_YES = 'Y';
  public static IS_USED_OWN_TRANSPORT_NO = 'N';
  public static CONFIRMATION_MESSAGE_FOR_ACCEPT_JOB = 'Are you sure you want to accept job?';
  public static CONFIRMATION_MESSAGE_FOR_DECLINE_JOB = 'Are you sure you want to decline job?';
  public static SUCCESS_MESSAGE_OF_ACCEPT_JOB = 'The job has been accepted successfully.';
  public static SUCCESS_MESSAGE_OF_DECLINE_JOB = 'The job has been declined successfully.';
  public static ERROR_MESSAGE_OF_ORDER_FINAL_PAYMENT = 'This order hasn\'t paid final payment.';
  public static ERROR_MESSAGE_OF_ORDER_SITE_CONTACT_DATA_EMPTY = 'Please add the site contact information before order Goods despatch process.';
  public static IS_DISPATCH_YES = 'Y';
  public static IS_DISPATCH_NO = 'N';
  public static IS_STOCK_REQUIRED_YES = 'Y';
  public static IS_STOCK_REQUIRED_NO = 'N';
  public static PRODUCT_RECOMMENDED_COLOR_TYPE_WJ = 'WJ';
  public static PRODUCT_RECOMMENDED_COLOR_TYPE_FG = 'FG';
  public static DEPOSIT_PAYMENT_ADJUSTMENT_SUCCESS = 'The deposit payment has been adjusted successfully.';
  public static DEPOSIT_PAYMENT_ADJUSTMENT_ERROR = 'The deposit payment has not been adjusted.';
  public static DEPOSIT_PAYMENT_ADJUSTMENT_PERCENTAGE_ERROR_0 = 'The number zero is prohibited. Kindly enter some value.';
  public static DEPOSIT_PAYMENT_ADJUSTMENT_PERCENTAGE_ERROR_100 = 'Adjust deposit payment value should be 100% only.';
  public static DEPOSIT_PAYMENT_ADJUSTMENT_VALUE_ERROR_0 = 'The number zero is prohibited. Kindly enter some value.';
  public static DEPOSIT_PAYMENT_ADJUSTMENT_VALUE_ERROR_100 = 'Adjusted deposit payment value should be less than or equal to the final total.';
  public static APPLIED_CREDIT_ADJUSTMENT_PERCENTAGE_ERROR_0 = 'The number zero is prohibited. Kindly enter some value.';
  public static APPLIED_CREDIT_ADJUSTMENT_PERCENTAGE_ERROR_100 = 'Adjusted applied credit value should be 100% only.';
  public static APPLIED_CREDIT_ADJUSTMENT_VALUE_ERROR_0 = 'The number zero is prohibited. Kindly enter some value.';
  public static APPLIED_CREDIT_ADJUSTMENT_VALUE_ERROR_100 = 'Adjusted applied credit value should be less than or equal to available credit.';
  public static APPLIED_CREDIT_ADJUSTMENT_VALUE_ERROR_SUBTOTAL = 'Adjusted applied credit value should be less than or equal to the sub total.';
  public static PRODUCT_PRICE_GREATER_THAN_ERROR = 'Product price must be less than or equal to 10000';
  public static PRODUCT_PERCENTAGE_GREATER_THAN_ERROR = 'Product applied discount should be 100% only.';
  public static IS_ARCHIVED_YES = 'Y';
  public static IS_ARCHIVED_NO = 'N';
  public static IS_DRAFT_YES = 'Y';
  public static IS_DRAFT_NO = 'N';
  public static IS_PAYMENT_DONE = 'Y';
  public static IS_PAYMENT_NOT_DONE = 'N';

  public static ADD_PRODUCT_TO_QUOTE_AFTER_DELIVERY_CHARGE_ADDED = {
    BODY_MESSAGE: 'This quote has already had a manual delivery fee calculated. Proceeding with further edits will require a new delivery fee to be manually applied. Do you still want to continue? ',
  }
  public static BIG_POST_JOB_ERROR_MESSAGE = 'This address has not delivered by big post.Please select another carrier.'

  public static UPDATE_PRODUCT_QUANTITY_AFTER_CREDIT_APPLIED = {
    BODY_MESSAGE: 'The quote applied credit will be removed. Are you sure you want update the quantity of this product?',
  }

  public static UPDATE_PRODUCT_DISCOUNT_AFTER_CREDIT_APPLIED = {
    BODY_MESSAGE: 'The quote applied credit will be removed. Are you sure you want update the discount of this product?',
  }

  public static UPDATE_AVAILABLE_BALANCE_AFTER_CREDIT_APPLIED = {
    BODY_MESSAGE: 'The quote applied credit will be removed. Are you sure you want update the available balance of this account?',
  }

  public static DELETE_PRODUCT_AFTER_CREDIT_APPLIED = {
    BODY_MESSAGE: 'The quote applied credit will be removed. Are you sure you want delete this product?',
  }

  public static ADD_PRODUCT_AFTER_CREDIT_APPLIED = {
    BODY_MESSAGE: 'The quote applied credit will be removed. Are you sure you want add this product?',
  }

  public static ADD_CONSUMABLE_CALCULATOR_AFTER_CREDIT_APPLIED = {
    BODY_MESSAGE: 'The quote applied credit will be removed. Are you sure you want add this consumable calculator?',
  }

  public static REMOVE_CONSUMABLE_CALCULATOR_AFTER_CREDIT_APPLIED = {
    BODY_MESSAGE: 'The quote applied credit will be removed. Are you sure you want removed this consumable calculator?',
  }

  public static EXPORT_QUOTE_INVOICE_URL = environment.hostUrl + '/quote-invoice';
  public static QUOTE_INVOICE_LINK_COPIED = 'Quote/Invoice link has been copied.'

  public static EXPORT_ORDER_TRACKING_URL = environment.hostUrl + '/track-order';
  public static ORDER_TRACKING_LINK_COPIED = 'Order tracking link has been copied.'

  public static PRODUCT_DELETED_YES = 1;
  public static PRODUCT_DELETED_NO = 0;

  public static FORk_TRUCK_ID = 1;
  public static HIAB_TRUCK_ID = 2;
  public static FLATAB_TRUCK_ID = 3;
  public static OTHER_TRUCK_ID = 4;
  public static WAREHOUSE_COLLECTION_TRUCK_ID = 5;


  public static LEAD_TIME = 'Available'
  public static LEAD_TIME_AVAILABLE = 'available'


  public static FILTER_AVAILABLE_STRING = [
    'Available',
    'available',
  ];
  public static FILTER_LOW_AVAILABLE_STRING = [
    'Low Stock',
  ];
  public static LEAD_TIME_IN_STOCK = 'In Stock'
  public static LEAD_TIME_LOW_STOCK = 'Low Stock'
  public static APPLY_GLUE_CALCULATOR_YES = 'YES';
  public static APPLY_GLUE_CALCULATOR_NO = 'NO';

  public static PRODUCT_DETAIL_SIZING_TABLE_TOOLTIP = "Lead times are indicative only. Although we take great care to ensure they're kept accurate, stock levels are constantly fluctuating. Our best advice is always to place orders as early as possible, thus reducing the likelihood of supply related delays disrupting your construction schedule.";
  // Confirmation Already Exist Product
  public static CONFIRMATION_CART_PRODUCT_DELETE_TITLE = 'Delete product';

  public static SHIPPING_RATE_LENGTH = 9999;

  public static QUANTITY_LENGTH = 9999;
  public static QUANTITY_LENGTH_ERROR_MESSAGE = 'Quantity should not be greater than 9999.';
  public static PRODUCT_ADDED_SUCCESSFULLY = 'Product has been added successfully.';

  public static STATUS_ADJUST_GC_BALANCE = 'AGB';
  public static STATUS_QUOTE_GC_CREDIT = 'QGC';
  public static STATUS_EXPIRED_GC_CREDIT = 'EGC';
  public static STATUS_EXPIRED_GC_DEBIT = 'EGD';
  public static STATUS_DONATED_GC_CREDIT = 'DGC';
  public static STATUS_QUOTE_GC_CREDIT_APPLY = 'QGCA';
  public static STATUS_DEDUCT_EARNED_GC_CREDIT = 'DEQGC';
  public static STATUS_REVERT_QUOTE_GC_CREDIT_FOR_DELETE = 'RQGCFD';
  public static STATUS_REVERT_QUOTE_GC_CREDIT_FOR_RESET = 'RQGCFR';
  public static STATUS_QUOTE_GC_CREDIT_RE_APPLY = 'QGCRA';
  public static STATUS_REVERT_ORDER_GC_CREDIT_FOR_CANCEL = 'RQGCFC';

  public static QUOTE_ORDER_DELETED_YES = 1;
  public static QUOTE_ORDER_DELETED_NO = 0;

  public static INVALID_URL_ERROR = 'Invalid URL. Please enter valid url';

  public static IS_MANUAL_SHIPPING_RATE_CHANGE_YES = 'Y';
  public static IS_MANUAL_SHIPPING_RATE_CHANGE_NO = 'N';

  public static IS_WAREHOUSE_COLLECTION_YES = 'Y';
  public static IS_WAREHOUSE_COLLECTION_NO = 'N';

  public static DISPATCHED_MAIL_TEMPLATE_KEY_NAME = 'trade-user-carrier-added';

  public static PDF_FILE_UPLOAD_ALERT = 'Please upload pdf file only'

  public static ALLOWED_FILE_TYPE_UPLOAD_PDF = ["pdf"];

  public static ALLOWED_UPLOAD_SIZE_MB_PDF = 40;


  public static UPLOAD_PDF = {
    UPLOAD_PDF_TYPE_VALIDATION_MESSAGE: 'Please upload PDF only',
    UPLOAD_PDF_SIZE_VALIDATION: 'Please upload less than 40 MB file'
  }

  public static MULTI_UPLOAD_FILE = {
    UPLOAD_FILE_TYPE_VALIDATION_MESSAGE: 'Only files with the following extensions are allowed: jpg, jpeg, png, heic, heif and pdf.',
    UPLOAD_FILE_TYPE_VALIDATION_MESSAGE_PARTIAL_PAYMENT: 'Only images and PDF files are allowed.',
    UPLOAD_FILE_SIZE_VALIDATION: 'Please upload less than 40 MB file'
  }

  public static UPLOADED_PDF = {
    UPLOAD_PDF_TYPE_MESSAGE: 'PDF Should be uploaded'
  }

  public static PLEASE_SELECT_PDF_FILE = {
    ONLY_UPLOAD_PDF_FILE_MESSAGE: 'Please select PDF file only.'
  }

  public static UPLOAD_PDF_FILE = 1

  //public static LOCALSTORAGE_REMOVE_ARRAY = ['dashboard-bkp','accounts', 'contacts','sample-requests','quotes','orders','products','settings','users','resource','projects','blogs','articles','faq','carrier','email-templates','consumables-calculator'];

  public static LOCALSTORAGE_REMOVE_ARRAY = ['accounts', 'contacts', 'sample-requests', 'quotes', 'orders', 'despatch', 'products', 'tasks'];

  public static OFFLINE_PAYMENT = [
    {ID: 'C', OFFLINE_PAY: 'Cash'},
    {ID: 'BT', OFFLINE_PAY: 'Bank Transfer'},
    {ID: 'CC', OFFLINE_PAY: 'Credit Card'},
    {ID: 'BP', OFFLINE_PAY: 'BPAY'},
    {ID: 'UN', OFFLINE_PAY: 'Unknown'}
  ]

  public static ACCOUNT_FILE_UPLOAD = 'Please upload image file only.'

  public static DELIVERY_CHARGE = 'Delivery charge should not be greater than 10000.'


  public static IS_DISABLE_EXPIRY_DEADLINE_YES = 'Y';
  public static IS_DISABLE_REACTIVATE_EXPIRY_DEADLINE_NO = 'N';

  public static QUOTE_IS_DISABLE_EXPIRY_DEADLINE_YES_CONFIRMATION_MESSAGE = 'Are you sure you want to disable the expiry deadline of this quote?';
  public static QUOTE_IS_REACTIVE_DISABLE_EXPIRY_DEADLINE_NO_CONFIRMATION_MESSAGE = 'Are you sure you want to enable expiry deadline of this quote?'

  public static QUOTE_SUCCESS_MESSAGE_IS_DISABLE_DEADLINE = 'Quote expiry deadline has been disabled successfully.';
  public static QUOTE_SUCCESS_MESSAGE_IS_REACTIVE_EXPIRY_DEADLINE = 'Quote expiry deadline has been enabled successfully.';

  public static IS_CONFIRM_STOCK_REQUIRED_DATE_YES = 'Y';
  public static IS_CONFIRM_STOCK_REQUIRED_DATE_NO = 'N';

  public static FILTER_PREMIUM_SEALER = ['DEFENDER', 'GOLD'];

  public static IS_DISABLE_GPSM_SYNC_YES = 'Y';
  public static IS_DISABLE_GPSM_SYNC_NO = 'N';

  public static ORDER_IS_DISABLE_GPSM_SYNC_CONFIRMATION_MESSAGE = 'Are you sure you want to disable the GPSM sync of this order?';
  public static ORDER_IS_ENABLE_GPSM_SYNC_CONFIRMATION_MESSAGE = 'Are you sure you want to enable GPSM sync of this order?'

  public static ORDER_SUCCESS_MESSAGE_FOR_DISABLE_GPSM_SYNC = 'GPSM sync has been disabled successfully.';
  public static ORDER_SUCCESS_MESSAGE_FOR_ENABLE_GPSM_SYNC = 'GPSM sync has been enabled successfully.'
  public static ORDER_SUCCESS_MESSAGE_FOR_UPDATE_GPSM_SHIPMENT_REFERENCE = 'GPSM shipment reference has been updated successfully.';
  public static UNABLE_EXPIRE_QUOTE_WHEN_DEADLINE_DISABLE = 'The expiry deadline is disabled, please enable first.';

  public static UNABLE_ORDER_STATUS_CHANGE_TO_DELIVERED = 'You are unable to update the order status due to final payment outstanding.';
  public static UNABLE_ORDER_STATUS_CHANGE_TO_FOLLOW_UP_OR_COMPLETED = 'You are unable to update the order status because the order has not been delivered.';

  public static IS_USER_CREATE_FROM_QUOTE = 'Y';
  public static IS_USER_CREATE_FROM_SAMPLE = 'Y';

  public static IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'tiff', 'tif', 'webp', 'heif', 'heic', 'ico'];
  public static DOCUMENT_EXTENSIONS = ['pdf', 'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'odt', 'ods', 'odp', 'txt', 'xml', 'csv'];

  public static IS_DEPOSIT_PAYMENT_PROCESSING_YES = 'Y';
  public static IS_DEPOSIT_PAYMENT_PROCESSING_NO = 'N';

  public static IS_FINAL_PAYMENT_PROCESSING_YES = 'Y';
  public static IS_FINAL_PAYMENT_PROCESSING_NO = 'N';

  public static IS_FULL_PAYMENT_PROCESSING_YES = 'Y';
  public static IS_FULL_PAYMENT_PROCESSING_NO = 'N';

  public static IS_DEPOSIT_SLIDER_ADJUST_YES = 'Y';
  public static IS_DEPOSIT_SLIDER_ADJUST_NO = 'N';

  public static IS_DOMESTIC_STOCK_TRANSFER_YES = 'Y';
  public static IS_DOMESTIC_STOCK_TRANSFER_NO = 'N';

  public static CONFIRMATION_MESSAGE_FOR_CONVERT_DRAFT_TO_QUOTE = {
    BODY_MESSAGE: 'Are you sure you want to convert this Draft into quote?',
  }

  public static USER_NOT_SELECT_ERROR_FOR_CONVERT_DRAFT_TO_QUOTE = 'Please select any one contact from existing';
  public static PROJECT_NAME_NOT_ENTER_ERROR_FOR_CONVERT_DRAFT_TO_QUOTE = 'Please enter project name';
  public static PRODUCT_NOT_SELECT_ERROR_FOR_CONVERT_DRAFT_TO_QUOTE = 'Please add product';

  public static QUOTE_ORDER_TOTAL_AMOUNT_FOR_DEPOSIT_SLIDER = 1000;

  public static TASK_CATEGORY = {
    'CC': 'Contact Create',
    'SD': 'Sample Delivered',
    'QC': 'Quote Create',
    'QEI': 'Quote Expiry Immanent',
    'DPD': 'Deposit Payment Due',
    'PP': 'Payment Processing',
    'FPO': 'Final Payment Outstanding',
    'OF': 'Order Followup',
  };

  public static QUOTE_CREATE = 'QC'
  public static CONTACT_CREATE = 'CC'
  public static SAMPLE_DELIVERED = 'SD'
  public static QUOTE_EXPIRY_IMMANENT = 'QEI'
  public static DEPOSIT_PAYMENT_DUE = 'DPD'
  public static PAYMENT_PROCESSING = 'PP'
  public static FINAL_PAYMENT_OUTSTANDING = 'FPO'
  public static ORDER_FOLLOW_UP = 'OF'

  public static TASK_PENDING = 'P'
  public static TASK_COMPLETED = 'C'
  public static TASK_COMPLETED_MESSAGE = 'Task has been completed successfully.';
  public static TASK_PENDING_MESSAGE = 'Task has been pending successfully.';
  public static CONFIRM_COMPLETE_TASK = 'Are you sure you want to complete this task?';
  public static CONFIRM_PENDING_TASK = 'Are you sure you want to revert this task to pending?';
  public static STATUS_CHANGES_SUCCESS = 'Assigned user has been changed successfully';


  public static UNABLE_SAMPLE_REQUEST_PUSH_BIG_POST_JOB = 'You are not allowed to push the big post job until the status change to "packaged".';

  public static UNABLE_TO_UPLOAD_PAYMENT_REMITTANCE = 'You are not able to upload payment remittance until quote is not accepted';
  public static UNABLE_TO_UPLOAD_OTHER_PAYMENT_REMITTANCE = 'You cannot upload another payment remittance until your processing payment not verified. If you need any assist please contact to administrator';
  public static ANTICIPATED_WAD_GREATER_ANTICIPATED_PCD = 'Anticipated warehouse arrival date should be greater or equal to Anticipated production completion date';
  /**
   * Identify task category
   */
  public static TASK_CATEGORIES = {
    'CONTACTS_REVIEW_CONTACT': 1,
    'CONTACTS_WELCOME_MESSAGE': 2,
    'SAMPLE_REQUEST_SEEK_FEEDBACK': 3,
    'QUOTES_OFFER_ASSISTANCE': 4,
    'QUOTES_REQUEST_ADDRESS': 5,
    'QUOTES_QUOTE_DELIVERY': 6,
    'QUOTES_INFORM_CLIENT': 7,
    'QUOTES_CONVERT_SALE': 8,
    'QUOTES_REQUEST_PAYMENT': 9,
    'QUOTES_VERIFY_PAYMENT': 10,
    'ORDERS_INSPECT_ORDER': 11,
    'ORDERS_SHIPMENT_DELAYED': 12,
    'ORDERS_CONFIRM_SRD': 13,
    'ORDERS_REQUEST_PAYMENT': 14,
    'ORDERS_VERIFY_PAYMENT': 15,
    'ORDERS_REBOOK_DELIVERY': 16,
    'ORDERS_SEEK_FEEDBACK': 17,
    'SAMPLE_REQUEST_VERIFY_DELIVERY': 18,
    'ORDER_VERIFY_DELIVERY': 19,
    'SAMPLE_REQUEST_SECONDARY_FEEDBACK': 20,
    'SAMPLE_REQUEST_COMPLETE_SAMPLE_REQUEST': 21,
    'ORDER_SECONDARY_FEEDBACK': 22,
    'ORDER_COMPLETE_ORDER': 23,
    'ORDER_CONFIRM_PRODUCTION_COMPLETION': 29,
    'OTHER_BAD_DEBT': 24,
    'OTHER_SRD_CONFLICT': 25,
    'OTHER_SRD_EXPIRED': 26,
    'ORDER_RECONCILE_ORDER': 27,
    'OTHER_WAREHOUSE_ARRIVAL_CONFLICT': 28,
    'CONTACTS_FOLLOW_UP_NEW_CONTACT': 30,
    'CONTACTS_CONFIRM_RESPONSE': 31,
  };
  public static CLIENT_RESPONDED = 1;
  public static CLIENT_NOT_RESPONDED = 0;
  public static RECIPIENT_DETAIL_UPDATE = 'Recipient detail has been updated successfully.'

  public static RE_ASSIGN_USER = 'reassign'

  public static AUTOMATED_CHANGE_ORDER_STATUS_TOOLTIP = 'Milestone update was automated';

  public static ORDER_AWAITING_FEEDBACK_NOTE_ID = 30;
  public static SAMPLE_AWAITING_FEEDBACK_NOTE_ID = 25;

  public static SRD_EXPIRED = 'SRD Expired';
  public static SRD_CONFLICT = 'SRD Conflict'

  public static GOODS_DISPATCHED_MILESTONE_STATUS_ARRAY = ['GDUNS', 'GDOTT', 'GDA', 'GDD', 'GD', 'GDCO', 'GDDE', 'CAN', 'FU', 'C'];

  // Link Payment Receipt Messages
  public static PAYMENT_RECEIPT_LINKED_SUCCESS_MESSAGE = 'Payment receipt has been linked successfully.';
  public static PAYMENT_STATUS_INCOMPLETE_ERROR_MESSAGE = "The receipt can't be linked until the payment is completed.";
  public static RECEIPT_ALREADY_LINKED_TO_PAYMENT_ERROR_MESSAGE = 'Payment receipt has already been linked to this payment.';
  public static DIFFERENT_RECEIPT_LINKED_ERROR_MESSAGE = 'Payment receipt has already been linked. Please select a different receipt.';
  public static RECEIPT_ALREADY_LINKED_ERROR_MESSAGE = 'Payment receipt has already been linked.';

  // Add Comment Message
  public static ADD_COMMENT_SUCCESS_MESSAGE = 'Comment added successfully.';
  public static UPDATE_COMMENT_SUCCESS_MESSAGE = 'Comment updated successfully.';
  public static NO_COMMENTS_AVAILABLE_ERROR_MESSAGE = 'No comments are available.';

  // Notification Message
  public static ALL_NOTIFICATION_MARKED_AS_READ_SUCCESS_MESSAGE = 'All notifications marked as read successfully.';
  public static NOTIFICATION_MARKED_AS_READ_SUCCESS_MESSAGE = 'Notification marked as read successfully.';
  public static NOTIFICATION_MARKED_AS_UNREAD_SUCCESS_MESSAGE = 'Notification marked as unread successfully.';

  // Notification Status
  public static NOTIFICATION_STATUS_ACTIVE = 1;
  public static NOTIFICATION_STATUS_INACTIVE = 0;

  public static QUOTE_ORDER_STATUS_DROPDOWN_USED_IN = 'T';
  public static ASSIGNED_TO_AND_COMPLETED_BY_USER_DROPDOWN_USED_IN = 'T';

  public static EMPTY_PARAGRAPH_HTML = '<p><br></p>';

  public static NOTE_NOTIFICATION = 'N';
  public static QUOTE_PRODUCT_NOTIFICATION = 'QP';


  // Sample Image Message
  public static CAPTURE_IMAGE_UPLOAD_SUCCESS_MESSAGE = 'Captured image uploaded successfully.';
  public static NO_CAMERA_ERROR_MESSAGE = 'No camera found. Please ensure a camera is connected and try again.';
  public static CAMERA_ACCESS_DENIED_ERROR_MESSAGE = 'Camera Access Denied. Please try again by approving the access.';
  public static UNSUPPORTED_BROWSER_ERROR_MESSAGE = 'Unsupported Browser. Please use a modern browser.';
  public static PERMISSION_DENIED_ERROR_MESSAGE = 'Permission denied';
  public static PACKED_QUANTITY_EXCEEDS_MESSAGE = 'Packed quantity should be less than or equal to required quantity'
  public static SAMPLE_IMAGE_DELETE_SUCCESSFULLY = 'Sample image has been deleted successfully.';
  public static QUANTITY_MUST_BE_GREATER_THAN_ZERO_ERROR_MESSAGE = 'Quantity must be greater than 0';

  public static VALID_QUANTITY_ERROR_MESSAGE = 'Please enter a valid quantity';
  public static INVALID_DATE_ERROR_MESSAGE = 'Invalid Date';
  public static VALID_DATE_ERROR_MESSAGE = 'Please enter a valid date';

  public static DELETE_SAMPLE_REQUEST_IMAGE_CONSTANTS = {
    TITLE: 'Delete Sample Image',
    BODY_MESSAGE: 'Are you sure you want delete this sample image?',
    NO: "No",
    YES: "Yes"
  }
  // Tender Messages
  public static UPDATE_QUOTE_CONFIRMATION = {
    TITLE: 'Update Tender Totals',
    BODY_MESSAGE: 'Quantities in this quote differ from the master tender. Would you like these revised totals to be applied to all existing quotes under this tender?',
    NO: "No",
    YES: "Yes"
  }

  public static TENDER_UPDATE_ALL_SUCCESS = 'All existing tenders are updated successfully';
  public static TENDER_PROMOTED_TO_PRIMARY_SUCCESS_MESSAGE = 'The secondary tender has been successfully set as the primary tender.';
  public static TENDER_UPDATE_ALL_FAILURE = 'Failed to update existing tenders.';
  public static TENDER_STATUS_PRIMARY = 'new';
  public static TENDER_STATUS_SECONDARY = 'existing';
  public static TENDER_TYPE_PRIMARY = 1;
  public static TENDER_TYPE_SECONDARY = 0;
  public static TENDER_SELECTION_ACTIVE = 1;
  public static TENDER_SELECTION_INACTIVE = 0;
  public static TENDER_FILTER_ACTIVE = 1;

  public static AVERAGE_DELIVERED_TIME = 'avg_delivered_time';

  public static IS_ACCEPTED_YES = 'Y';
  public static IS_ACCEPTED_NO = 'N';

  // Scan Tracking Number Message
  public static ADD_TRACKING_NUMBER_SUCCESS_MESSAGE = 'Scanned tracking number added successfully.';
  public static VIDEO_INPUT = 'videoinput';
  public static NOT_ALLOWED_ERROR = 'NotAllowedError';
  public static PERMISSION_DENIED_ERROR = 'PermissionDeniedError';
  public static SAMPLE_PRODUCT_IMAGE = 'SPI';
  public static SAMPLE_FORMAT_HERO_IMAGE = 'SFHI';
  public static PRODUCT_IMAGE = 'PI';
  public static FORMAT_HERO_IMAGE = 'FHI';
  public static PRODUCT_IMAGES = 'Images';
  public static SAMPLE_PRODUCT_IMAGES = 'Sample'
  public static METRO_COUPON_VALUE = 16.65;
  public static LINK_COUPON_VALUE = 5.00;
  public static COUPON_QUANTITY_GREATER_THAN_ZERO = "Number of coupon quantity must be greater than zero.";
  public static METRO_COUPON_START = '1';
  public static LINK_COUPON_START = '9';
  public static TRACKING_NUMBER_STARTS_WITH = "Tracking number must start with 1 or 9.";
  public static DELETE_TRACKING_NUMBER_SUCCESS_MESSAGE = "Tracking number has been deleted successfully";

  // Scan Sample Product Sku Message
  public static INVALID_SKU_MESSAGES = {
    TITLE: 'Invalid SKU',
    BODY_MESSAGE: 'Entered sample SKU is Invalid. Please try again',
    NO: "No",
    YES: "Yes"
  };
  public static VALID_SKU_MESSAGES = {
    TITLE: 'Confirm Add Product',
    BODY_MESSAGE: "The entered SKU is valid, but it's not currently part of the sample request. Would you like to add it to this request?",
    NO: "No",
    YES: "Yes"
  };
  public static INVALID_SKU_ERROR_MESSAGE = 'Invalid SKU. Please try again.';
  public static ADD_PRODUCT_DEFAULT_PACKED_QUANTITY = 1

  // Split Delivery Message
  public static ERROR_MESSAGE_ZERO_QUANTITY_PRODUCTS = "You cannot split sample request as product have zero packed quantity.";

  // Logistic Type Id's
  public static LOGISTIC_TYPE_IDS = {
    GO_LOGISTIC: 2,
    BIGPOST: 1,
    AUS_POST: 3,
    OTHER: 4
  };

  public static SAMPLE_PACKAGES_STATUS_ID = 18;
  public static SAMPLE_STATUS_COMPLETE_ID = 31;
  public static PREVIEW_LABEL_PDF_TITLE = 'Preview PDF Label';
  public static PACKAGE_DETAIL_UPDATE_SUCCESS_MESSAGE = 'Package detail has been updated successfully.'
  public static CUSTOM_SAMPLE_PACKING_BOX_ID = 6;

  // Product Row Drag And Drop Message
  public static PRODUCT_ORDER_SEQUENCE_UPDATE_SUCCESS_MESSAGE = "Product sequence has been updated successfully";

  // Sku Copied Message
  public static SKU_COPIED = 'Sku copied!';

  // For flooring and walling type
  public static FLOORING_TYPE_NAME = 'flooring';
  public static WALLING_TYPE_NAME = 'walling';
  public static FLOORING_TYPE_ID = 1;
  public static WALLING_TYPE_ID = 2;
  public static IMAGE_SIZE_38_X_38 = '?tr=w-38,h-38';

  public static SAMPLE_LIST_VIEW_LIMIT = 12;

  public static WASTAGE_TOOLTIP = 'To guarantee sufficient material for your project, we recommend adding an additional 15% to account for potential cuts and unexpected breaks or splits during installation to the listed available quantity'

  public static PAYMENT_MODE_CASH_SHORT = 'C';
  public static PAYMENT_MODE_ONLINE_SHORT = 'ON';
  public static PAYMENT_MODE_BANK_TRANSFER_SHORT = 'BT';
  public static PAYMENT_MODE_CREDIT_CARD_SHORT = 'CC';
  public static PAYMENT_MODE_UNKNOWN_SHORT = 'UN';
  public static PAYMENT_MODE_BPAY_SHORT = 'BP';
}

