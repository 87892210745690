export const environment = {
  production: true,
  apiUrl: 'https://api.gatherco.com.au/v1/',
  clientId: '15072021002',
  clientSecret: 'GKBC2021157',
  frontendClientId: '15072021001',
  frontendClientSecret: 'KLURQWCN15',
  grantType: 'password',
  Alpha: 'neuDFUWMTb07XGpsKxy4w+oiSRHCa3j2NcZvIALErtfO8V/gdmz5Q1Y6qP9JklBh',
  USER_TYPE_TRADE: 'Trade',
  USER_TYPE_GENERAL: 'General',
  facebookAppId: '840587173156641',
  googleAppId: '818998394662-tt1fucr9omf2a8m8l00jdecd2fqe8hhb.apps.googleusercontent.com',
  imageKitEndpoint: 'https://ik.imagekit.io/gatherco',
  hostUrl: "https://gatherco.com.au", // URL where this app is hosted
  guestPaymentUrl: "https://www.gatherco.com.au/guest/payment",
  imageKitAssetFolder: "WEBSITE_ASSETS",
};
